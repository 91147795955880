import Link from 'next/link';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useLogin } from '~/features/auth';
import LoginError from '~/features/error-message/LoginError';
import AuthLayout from '~/features/layouts/AuthLayout';
import PageTransition from '~/features/layouts/PageTransition';
import Button from '~/shared/ui/button';
import FormActions from '~/shared/ui/form-actions';
import FormGroup from '~/shared/ui/form-group';
import Input from '~/shared/ui/input';
import { PanelTitle, PanelIntro } from '~/shared/ui/panel';

function PermissionDenied() {
  const { t } = useTranslation('auth');
  const { login, loading, error } = useLogin();

  async function handleSubmit(e) {
    e.preventDefault();

    const variables = {
      email: e.currentTarget.email.value,
      password: e.currentTarget.password.value,
    };

    login(variables);
  }

  return (
    <PageTransition>
      <AuthLayout back="/">
        <PanelTitle>{t('permission_denied.title')}</PanelTitle>
        <PanelIntro>{t('permission_denied.intro')}</PanelIntro>
        <form onSubmit={handleSubmit}>
          <LoginError error={error} />
          <FormGroup
            label={t('login.general.fields.email.label')}
            id="login-username"
          >
            <Input
              type="email"
              name="email"
              placeholder={t('login.general.fields.email.placeholder')}
            />
          </FormGroup>
          <FormGroup
            label={t('login.general.fields.password.label')}
            id="login-password"
          >
            <Input
              type="password"
              name="password"
              placeholder={t('login.general.fields.password.placeholder')}
            />
          </FormGroup>
          <FormActions>
            <Button type="submit" iconAfter="arrow-right" disabled={loading}>
              {t('permission_denied.button_login')}
            </Button>
            <Link href="/login/forgot-password">
              {t('login.button_forgot_password')}
            </Link>
          </FormActions>
        </form>
      </AuthLayout>
    </PageTransition>
  );
}

export default PermissionDenied;
