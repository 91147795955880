import React, { forwardRef, LegacyRef, ReactNode } from 'react';

import {
  StyledButton,
  SIZE,
  VARIANT,
  StyledLink,
  ButtonVariant,
  ButtonSize,
} from './styles';
export type { ButtonSize, ButtonVariant } from './styles';
import Icon, { IconProps } from '../icon';

interface ButtonProps {
  as?: React.ElementType;
  type?: 'button' | 'submit';
  variant?: ButtonVariant;
  size?: ButtonSize;
  iconBefore?: IconProps['icon'];
  iconAfter?: IconProps['icon'];
  label?: ReactNode;
  children?: ReactNode;
  href?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  disabled?: boolean;
}

const Button = forwardRef<HTMLElement, ButtonProps>(function Button(
  {
    as,
    type = 'button',
    variant = VARIANT.SOLID,
    size = SIZE.DEFAULT,
    iconBefore,
    iconAfter,
    label,
    children,
    onClick,
    href,
    ...props
  },
  ref,
) {
  const Component = href ? StyledLink : StyledButton;

  return (
    <Component
      as={as}
      ref={ref as LegacyRef<any>}
      type={type === 'submit' ? 'submit' : 'button'}
      variant={variant}
      size={size}
      onClick={onClick}
      href={href}
      {...props}
    >
      <span>
        {iconBefore && <Icon className="icon-before" icon={iconBefore} />}
        {label}
        {children}
        {iconAfter && <Icon className="icon-after" icon={iconAfter} />}
      </span>
    </Component>
  );
});

Button.displayName = 'Button';

export default Button;
