import styled from '@emotion/styled';
import { NextSeo } from 'next-seo';
import { bool, node, string } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BolFooter } from '~/shared/ui/bol-footer';

import PageActions from './PageActions';
import PageContentTransition from './PageContentTransition';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* min-height: 100vh; */
  padding-bottom: 80px;
  flex: 1;
`;
const FixedLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 100% 0 0;
`;

function BaseLayout({ children, pageActions, fixedHeight, title }) {
  const { t } = useTranslation('general');
  const Wrapper = fixedHeight ? FixedLayout : Layout;

  return (
    <Wrapper key="base-layout-wrapper">
      <NextSeo title={t(title)} />

      <PageActions>{pageActions}</PageActions>
      <PageContentTransition>{children}</PageContentTransition>
      <BolFooter label={t('bol_footer.label')} />
    </Wrapper>
  );
}
BaseLayout.propTypes = {
  title: string,
  fixedHeight: bool,
  children: node.isRequired,
  pageActions: node,
};
BaseLayout.defaultProps = {
  title: null,
  fixedHeight: false,
  pageActions: null,
};

export default BaseLayout;
