import {
  ApolloClient,
  type DefaultOptions,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import { createUploadLink } from 'apollo-upload-client';

import { API_BASE_URL } from '~/shared/config';

import { authErrorLink, authLink } from './auth';
import possibleTypes from './possible-types.main.generated';
import appolloRequestLoggerLinks from './utils/apollo-request-logger';
import { pageCountPagination } from './utils/type-policies';

// Load Apollo error messages, to figure out https://app.rollbar.com/a/doctorlove/fix/item/145001_vives-fonemi/103?utm_campaign=new_item_message&utm_medium=slack&utm_source=rollbar-notification
loadDevMessages();
loadErrorMessages();

const httpLink = new HttpLink({
  uri: API_BASE_URL,
});
const uploadLink = createUploadLink({
  uri: API_BASE_URL,
});
const linkFlow = from([
  ...appolloRequestLoggerLinks,
  authLink,
  authErrorLink,
  httpLink,
]);
const adminLinkFlow = from([
  ...appolloRequestLoggerLinks,
  authLink,
  authErrorLink,
  uploadLink,
]);

/**
 * Disable all caching, because `admin` and `app` play havoc with InMemoryCache
 *
 * Getting lots of rollbar errors, but unable to reproduce.
 */
const defaultOptionsWithoutCachingEver: DefaultOptions = {
  query: {
    fetchPolicy: 'no-cache',
  },
  mutate: {
    fetchPolicy: 'no-cache',
  },
  watchQuery: {
    fetchPolicy: 'no-cache',
  },
};

const appClient = new ApolloClient({
  connectToDevTools: process.env.NODE_ENV === 'development',
  cache: new InMemoryCache({
    ...possibleTypes,
    resultCaching: false,
    typePolicies: {
      AppQueries: {
        keyFields: false,
      },
      AdminQueries: {
        keyFields: false,
        // important to define the merge policy on fields of the AdminQueries type  if defined
        // directly on `SchoolsPaginator` for example the admin query does not know what to do
        fields: {
          users: pageCountPagination(),
          schools: pageCountPagination(),
          schoolYears: pageCountPagination(),
          classGroups: pageCountPagination(),
          students: pageCountPagination(),
          teachers: pageCountPagination(),
          words: pageCountPagination(),
          sounds: pageCountPagination(),
          skillTests: pageCountPagination(),
        },
      },
    },
  }),
  link: linkFlow,
  defaultOptions: defaultOptionsWithoutCachingEver,
});

// A client without caching, merge policies, ..., because admins change stuff
export const adminClient = new ApolloClient({
  connectToDevTools: false && process.env.NODE_ENV === 'development',
  cache: new InMemoryCache({
    ...possibleTypes,
    resultCaching: false,
    typePolicies: {
      AppQueries: {
        keyFields: false,
      },
      AdminQueries: {
        keyFields: false,
      },
    },
  }),
  link: adminLinkFlow,
  defaultOptions: defaultOptionsWithoutCachingEver,
});

export default appClient;
