import { type Theme, css } from '@emotion/react';
import styled from '@emotion/styled';

type AlertType = 'error' | 'info' | 'success';
type AlertSize = 'default' | 'sm';

function sentiment({ theme, type }: { theme: Theme; type?: AlertType }) {
  switch (type) {
    case 'error':
      return css`
        background-color: ${theme.error};
      `;
    case 'success':
      return css`
        background-color: ${theme.positive};
      `;
    case 'info':
    default:
      return css`
        background-color: ${theme.info};
      `;
  }
}

const Alert = styled.div<{ type?: AlertType; size: AlertSize }>`
  border-radius: 15px;
  padding: 10px 20px;
  font-size: ${(p) => (p.size === 'sm' ? '0.875rem' : '1rem')};
  font-weight: ${(p) => (p.size === 'sm' ? '400' : '600')};
  margin-bottom: 20px;

  color: ${(p) => p.theme.bright};
  ${sentiment}

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
  }

  pre {
    cursor: pointer;
    font-family: monospace;
    background: ${(p) => p.theme.bright};
    color: ${(p) => p.theme.dark200};
    padding: 10px;
    border-radius: 2px;
    margin: 10px 0;
    white-space: pre-wrap;
  }
`;

export default Alert;
