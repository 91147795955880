import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFeedbackAudio } from '~/shared/audio/feedback';
import { STATIC_DIR } from '~/shared/config';
import { breakpoint } from '~/shared/styles/utils';
import Button from '~/shared/ui/button';
import FormActions from '~/shared/ui/form-actions';
import Panel, { PanelIntro, PanelTitle } from '~/shared/ui/panel';
import playSingleAudioFile from '~/shared/utils/play-single-audio-file';

const MegaPhone = styled.img`
  display: block;
  margin: -60px 0 20px -50px;
  max-width: 274px;
  width: 100%;
  height: auto;

  ${breakpoint('medium')} {
    margin: -120px 0 20px -70px;
  }
`;

type TestAudioModalProps = {
  onDismiss: () => void;
  Wrapper: React.ComponentType<{ children?: React.ReactNode }>;
};

function TestAudioModal({
  Wrapper = Panel,
  onDismiss,
  ...props
}: TestAudioModalProps) {
  const { t } = useTranslation('test_audio');
  const [playing, setPlaying] = useState(false);
  const { loading, findOne } = useFeedbackAudio();

  const testAudio = async () => {
    if (playing) {
      return;
    }

    setPlaying(true);
    playSingleAudioFile(findOne('instruction-test-audio'), () => {
      setPlaying(false);
    });
  };

  return (
    <Wrapper {...props}>
      <MegaPhone src={`${STATIC_DIR}images/megaphone.png`} alt="" />
      <PanelTitle>{t('title')}</PanelTitle>
      <PanelIntro>{t('subtitle')}</PanelIntro>
      <FormActions>
        <Button
          variant="outline"
          iconAfter="sound"
          disabled={playing || loading}
          onClick={testAudio}
        >
          {t('button_test_audio')}
        </Button>
        <Button
          iconAfter="arrow-right"
          disabled={playing || loading}
          onClick={onDismiss}
        >
          {t('button_continue')}
        </Button>
      </FormActions>
    </Wrapper>
  );
}
TestAudioModal.defaultProps = {
  Wrapper: Panel,
};

export default TestAudioModal;
