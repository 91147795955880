import styled from '@emotion/styled';
import React, { SVGProps } from 'react';

import icons from './icons';

const StyledSvg = styled.svg`
  display: inline-block;
  vertical-align: middle;
  height: 1em;
  width: auto;
`;

export interface IconProps extends SVGProps<SVGSVGElement> {
  className?: string;
  icon: keyof typeof icons;
}

function Icon({ className, icon, ...props }: IconProps) {
  return (
    <StyledSvg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {icons[icon] || null}
    </StyledSvg>
  );
}

export default Icon;
