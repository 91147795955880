export const ROLE_TEACHER = 'teacher';
export const ROLE_SCHOOL_ADMIN = 'school-admin';
export const ROLE_ADMIN = 'admin';
export const ROLE_STUDENT = 'student';
export const ROLE_ROOT = 'root';

const ROLE_HIERARCHY = {
  [ROLE_ROOT]: [ROLE_TEACHER, ROLE_SCHOOL_ADMIN, ROLE_ADMIN],
  [ROLE_ADMIN]: [ROLE_SCHOOL_ADMIN, ROLE_TEACHER],
  [ROLE_SCHOOL_ADMIN]: [ROLE_TEACHER],
};

function ensureArray(role) {
  if (Array.isArray(role)) {
    return role;
  }
  return [role];
}

/**
 * @param {object} user
 * @param {string|array} requestedRoles if an empty array it will be considered as "just needs a user"
 * @param {boolean} requireAll require ALL requested roles or a single one is okay
 * @returns
 */
export function hasRequiredRole(user, requestedRoles, requireAll = false) {
  const userRoles = user?.roles?.map((role) => role.name) || [];
  const roleArray = ensureArray(requestedRoles);

  for (let i = 0; i < roleArray.length; i += 1) {
    const role = roleArray[i];
    const hasSingleRoleAccess =
      !role ||
      userRoles.includes(role) ||
      !!userRoles.find((userRole) => ROLE_HIERARCHY[userRole]?.includes(role));

    if (hasSingleRoleAccess) {
      if (!requireAll) {
        return true;
      }
    } else if (requireAll) {
      return false;
    }
  }

  return (user && roleArray.length === 0) || requireAll;
}

/**
 * @param {object} user
 * @returns
 */
export function hasAnyAdminRole(user) {
  // teacher is the lowest possible role (at this moment), inherited by all higher roles
  return hasRequiredRole(user, ROLE_TEACHER);
}

export default ROLE_HIERARCHY;
