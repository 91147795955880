import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import * as Types from '~/shared/api/models.main.generated';
const defaultOptions = { context: { clientName: 'app' } } as const;
export type AuthenticationPayloadFragment = {
  __typename?: 'AuthenticationPayload';
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
  tokenType: string;
  user?: {
    __typename?: 'User';
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    name: string;
    avatar?: { __typename?: 'Media'; id: string; src?: string | null } | null;
    roles: Array<{ __typename?: 'Role'; id: string; name: string }>;
  } | null;
};

export type LoginMutationVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
  password: Types.Scalars['String']['input'];
}>;

export type LoginMutationData = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'AuthenticationPayload';
    accessToken: string;
    refreshToken: string;
    expiresIn: number;
    tokenType: string;
    user?: {
      __typename?: 'User';
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      name: string;
      avatar?: { __typename?: 'Media'; id: string; src?: string | null } | null;
      roles: Array<{ __typename?: 'Role'; id: string; name: string }>;
    } | null;
  };
};

export type ResetPasswordMutationVariables = Types.Exact<{
  token: Types.Scalars['String']['input'];
  password: Types.Scalars['String']['input'];
}>;

export type ResetPasswordMutationData = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'AuthenticationPayload';
    accessToken: string;
    refreshToken: string;
    expiresIn: number;
    tokenType: string;
    user?: {
      __typename?: 'User';
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      name: string;
      avatar?: { __typename?: 'Media'; id: string; src?: string | null } | null;
      roles: Array<{ __typename?: 'Role'; id: string; name: string }>;
    } | null;
  };
};

export type StudentAuthPayloadFragment = {
  __typename?: 'StudentAuthenticationPayload';
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
  tokenType: string;
  user?: {
    __typename?: 'Student';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    classGroups: Array<{ __typename?: 'ClassGroup'; id: string }>;
  } | null;
};

export type LoginFromHomeMutationVariables = Types.Exact<{
  username: Types.Scalars['String']['input'];
}>;

export type LoginFromHomeMutationData = {
  __typename?: 'Mutation';
  loginAsStudent: {
    __typename?: 'StudentAuthenticationPayload';
    accessToken: string;
    refreshToken: string;
    expiresIn: number;
    tokenType: string;
    user?: {
      __typename?: 'Student';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      classGroups: Array<{ __typename?: 'ClassGroup'; id: string }>;
    } | null;
  };
};

export type AdminLoginAsStudentMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type AdminLoginAsStudentMutationData = {
  __typename?: 'Mutation';
  admin: {
    __typename?: 'AdminMutations';
    loginAsStudent: {
      __typename?: 'StudentAuthenticationPayload';
      accessToken: string;
      refreshToken: string;
      expiresIn: number;
      tokenType: string;
      user?: {
        __typename?: 'Student';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        classGroups: Array<{ __typename?: 'ClassGroup'; id: string }>;
      } | null;
    };
  };
};

export const AuthenticationPayloadFragmentDoc = gql`
  fragment authenticationPayload on AuthenticationPayload {
    accessToken
    refreshToken
    expiresIn
    tokenType
    user {
      id
      email
      firstName
      lastName
      name
      avatar {
        id
        src
      }
      roles {
        id
        name
      }
    }
  }
`;
export const StudentAuthPayloadFragmentDoc = gql`
  fragment studentAuthPayload on StudentAuthenticationPayload {
    accessToken
    refreshToken
    expiresIn
    tokenType
    user {
      id
      firstName
      lastName
      classGroups {
        id
      }
    }
  }
`;
export const LoginOperation = gql`
  mutation login($email: String!, $password: String!) {
    login(input: { email: $email, password: $password }) {
      ...authenticationPayload
    }
  }
  ${AuthenticationPayloadFragmentDoc}
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutationData,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutationData,
    LoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutationData, LoginMutationVariables>(
    LoginOperation,
    options,
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutationData>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutationData,
  LoginMutationVariables
>;
export const ResetPasswordOperation = gql`
  mutation resetPassword($token: String!, $password: String!) {
    login: resetPassword(input: { token: $token, password: $password }) {
      ...authenticationPayload
    }
  }
  ${AuthenticationPayloadFragmentDoc}
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutationData,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutationData,
    ResetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordMutationData,
    ResetPasswordMutationVariables
  >(ResetPasswordOperation, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<ResetPasswordMutationData>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutationData,
  ResetPasswordMutationVariables
>;
export const LoginFromHomeOperation = gql`
  mutation loginFromHome($username: String!) {
    loginAsStudent(input: { username: $username }) {
      ...studentAuthPayload
    }
  }
  ${StudentAuthPayloadFragmentDoc}
`;
export type LoginFromHomeMutationFn = Apollo.MutationFunction<
  LoginFromHomeMutationData,
  LoginFromHomeMutationVariables
>;

/**
 * __useLoginFromHomeMutation__
 *
 * To run a mutation, you first call `useLoginFromHomeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginFromHomeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginFromHomeMutation, { data, loading, error }] = useLoginFromHomeMutation({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useLoginFromHomeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginFromHomeMutationData,
    LoginFromHomeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LoginFromHomeMutationData,
    LoginFromHomeMutationVariables
  >(LoginFromHomeOperation, options);
}
export type LoginFromHomeMutationHookResult = ReturnType<
  typeof useLoginFromHomeMutation
>;
export type LoginFromHomeMutationResult =
  Apollo.MutationResult<LoginFromHomeMutationData>;
export type LoginFromHomeMutationOptions = Apollo.BaseMutationOptions<
  LoginFromHomeMutationData,
  LoginFromHomeMutationVariables
>;
export const AdminLoginAsStudentOperation = gql`
  mutation adminLoginAsStudent($id: ID!) {
    admin {
      loginAsStudent(id: $id) {
        ...studentAuthPayload
      }
    }
  }
  ${StudentAuthPayloadFragmentDoc}
`;
export type AdminLoginAsStudentMutationFn = Apollo.MutationFunction<
  AdminLoginAsStudentMutationData,
  AdminLoginAsStudentMutationVariables
>;

/**
 * __useAdminLoginAsStudentMutation__
 *
 * To run a mutation, you first call `useAdminLoginAsStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminLoginAsStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminLoginAsStudentMutation, { data, loading, error }] = useAdminLoginAsStudentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminLoginAsStudentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminLoginAsStudentMutationData,
    AdminLoginAsStudentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminLoginAsStudentMutationData,
    AdminLoginAsStudentMutationVariables
  >(AdminLoginAsStudentOperation, options);
}
export type AdminLoginAsStudentMutationHookResult = ReturnType<
  typeof useAdminLoginAsStudentMutation
>;
export type AdminLoginAsStudentMutationResult =
  Apollo.MutationResult<AdminLoginAsStudentMutationData>;
export type AdminLoginAsStudentMutationOptions = Apollo.BaseMutationOptions<
  AdminLoginAsStudentMutationData,
  AdminLoginAsStudentMutationVariables
>;
