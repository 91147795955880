'use client';

import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';

import Button from '~/shared/ui/button';
import { VARIANT } from '~/shared/ui/button/styles';
import FienZwaait from '~/shared/ui/fien-zwaait';
import FormActions from '~/shared/ui/form-actions';
import Modal from '~/shared/ui/modal';
import { PanelIntro, PanelTitle } from '~/shared/ui/panel';

import useGuessedTimezoneLocale from './use-guessed-timezone-locale';
import { useLoginState } from '../auth';

export const LOCATION_SWITCH_KEY = 'vives-fonemi-location-switch';

type LocationGuesserProps = {
  active: boolean;
};

export function LocationGuesser({ active }: LocationGuesserProps) {
  const { query, replace, route } = useRouter();
  const { 'location-check': queryLocationCheck, ...restQuery } = query;
  // should be '0' but add a few options, just to be sure
  const skipLocationCheckByQuery =
    queryLocationCheck === '0' || queryLocationCheck === 'false';
  const guessedTimezoneLocale = useGuessedTimezoneLocale();
  const [{ dismissed = false } = {}, setLocaleSwitchState] = useLocalStorage(
    LOCATION_SWITCH_KEY,
    { dismissed: false },
  );
  const { t, i18n } = useTranslation('general', {
    keyPrefix: 'suggested_location',
  });
  const currentLocale = i18n.language;
  const { isLoggedIn } = useLoginState();
  const skip =
    skipLocationCheckByQuery ||
    guessedTimezoneLocale === currentLocale ||
    guessedTimezoneLocale === undefined ||
    dismissed;
  const { asPath } = useRouter();

  const dismiss = useCallback(() => {
    setLocaleSwitchState({ dismissed: true });
  }, [setLocaleSwitchState]);

  /**
   * This effect will permanently dismiss the popup when a user is logged in.
   * (Since they are logged in/have an account for this locale, they are probably in the right place.)
   */
  useEffect(() => {
    // consider logged in users in the right place
    if (isLoggedIn) {
      dismiss();
    }
  }, [isLoggedIn, dismiss]);

  /**
   * This effect will redirect the user to the same page without the `location-check` query parameter
   */
  useEffect(() => {
    if (skipLocationCheckByQuery) {
      dismiss();
      replace({
        pathname: route,
        query: {
          ...restQuery,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dismiss, skipLocationCheckByQuery]);

  if (!active) {
    return null;
  }

  return (
    <Modal open={!skip} onClose={() => dismiss()}>
      <PanelTitle>{t('title')}</PanelTitle>
      <PanelIntro>
        <Trans t={t} i18nKey={'intro'} />
        {/* {t('intro')} */}
      </PanelIntro>
      <FormActions>
        <Button variant={VARIANT.OUTLINE} onClick={dismiss}>
          {t('stay')}
        </Button>
        <Button href={`${t('otherDomain')}${asPath}`}>{t('leave')}</Button>
      </FormActions>
      <FienZwaait
        style={{
          width: 100,
          height: 100,
          position: 'absolute',
          bottom: '100%',
          right: '5%',
        }}
        mode="clip"
      />
    </Modal>
  );
}
