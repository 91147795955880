import React from 'react';

import { StyledBolFooter } from './bol-footer.styles';

interface BolFooterProps {
  className?: string;
  label?: string;
  href?: string;
  transparent?: boolean;
  fixed?: boolean;
  target?: string;
  children?: React.ReactNode;
}

export function BolFooter({
  className,
  label = 'Created by',
  href = 'https://www.bitsoflove.be/',
  transparent = false,
  fixed = true,
  target = '_blank',
}: BolFooterProps) {
  return (
    <StyledBolFooter
      transparent={transparent}
      fixed={fixed}
      className={className}
    >
      <a href={href} target={target}>
        <span>{label}</span>{' '}
        <div className="bol-footer-heart">
          <div className="bar1" />
          <div className="bar2" />
          <div className="bar3" />
          <div className="bar4" />
          <div className="bar5" />
          <div className="bar6" />
          <div className="bar7" />
        </div>
        <span>Bits of Love</span>
      </a>
    </StyledBolFooter>
  );
}
