import { type Theme, css } from '@emotion/react';
import styled from '@emotion/styled';
import { shade } from 'polished';

export const VARIANT = {
  SOLID: 'solid',
  OUTLINE: 'outline',
  SECONDARY: 'secondary',
  DANGER: 'danger',
};

export const SIZE = {
  DEFAULT: 'default',
  SMALL: 'small',
};

export type ButtonVariant = keyof typeof variantStyles;
export type ButtonSize = keyof typeof sizes;

const variantStyles = {
  [VARIANT.SOLID]: (p: { theme: Theme }) => css`
    background-color: ${p.theme.brand};
    box-shadow: ${p.theme.shadows.small};
    border-color: transparent;
    color: ${p.theme.bright};

    &:hover {
      background-color: ${shade(0.1, p.theme.brand)};
    }
  `,
  [VARIANT.OUTLINE]: (p: { theme: Theme }) => css`
    background-color: transparent;
    color: ${p.theme.brand};

    &:hover {
      background-color: ${p.theme.brand};
      color: ${p.theme.bright};
      border-color: transparent;
    }
  `,
  [VARIANT.SECONDARY]: (p: { theme: Theme }) => css`
    background-color: ${p.theme.secondaryDarker100};
    color: ${p.theme.bright};
    border-color: transparent;

    &:hover {
      background-color: ${shade(0.1, p.theme.secondaryDarker100)};
    }
  `,
  [VARIANT.DANGER]: (p: { theme: Theme }) => css`
    background-color: ${p.theme.negative};
    color: ${p.theme.bright};
    border-color: transparent;

    &:hover {
      background-color: ${shade(0.1, p.theme.negative)};
    }
  `,
} as const;
const sizes = {
  [SIZE.DEFAULT]: css`
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: 700;

    .icon-before,
    .icon-after {
      font-size: 1.25rem;
    }
  `,
  [SIZE.SMALL]: css`
    padding: 6px 10px;
    font-size: 0.875rem;
    line-height: 1.1875rem;
    font-weight: 400;

    .icon-before,
    .icon-after {
      font-size: 1rem;
    }
  `,
} as const;

const baseStyles = (p: {
  theme: Theme;
  variant?: ButtonVariant;
  size?: ButtonSize;
}) => css`
  cursor: pointer;
  display: inline-block;

  > span {
    display: flex;
    align-items: center;
  }

  font-family: Open Sans;
  font-size: 1rem;
  line-height: 25px;
  border: 2px solid;
  border-radius: 72px;
  transition:
    background-color 0.2s,
    color 0.2s 0.1s,
    border-color 0.2s 0.1s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  ${variantStyles[p.variant || VARIANT.SOLID]?.(p) ||
  variantStyles[VARIANT.SOLID](p)}
  ${sizes[p.size || SIZE.DEFAULT] || sizes[SIZE.DEFAULT]}

  .icon-before {
    margin: 0 10px 0 0;
  }
  .icon-after {
    margin: 0 0 0 10px;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:focus {
    border-color: ${p.theme.bright100};
    outline: none;
  }
`;

export const StyledButton = styled.button<{
  variant: ButtonVariant;
  size: ButtonSize;
}>`
  ${baseStyles}
`;

export const StyledLink = styled.a<{
  variant: ButtonVariant;
  size: ButtonSize;
}>`
  ${baseStyles}
  text-decoration: none;
`;
