export function ucFirst(string) {
  return `${string?.slice(0, 1).toUpperCase()}${string?.slice(1)}`;
}

export function personInitials(person) {
  if (!person) {
    return '-';
  }
  if (person.firstName && person.lastName) {
    return `${person?.firstName?.[0]}${person?.lastName?.[0]}`.toUpperCase();
  }
  if (person.firstName) {
    return person?.firstName[0].toUpperCase();
  }
  return person.name?.[0].toUpperCase() || '-';
}

export function personName(person) {
  if (!person) {
    return '-';
  }
  if (person.firstName && person.lastName) {
    return `${person?.firstName?.toUpperCase()} ${person?.lastName}`;
  }
  if (person.firstName) {
    return person?.firstName;
  }
  return person.name || '-';
}

export function firstLetter(value) {
  return value?.slice(0, 1) || '';
}
