import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { node } from 'prop-types';
import React, { useRef } from 'react';
import { TransitionGroup, Transition } from 'react-transition-group';
import { ENTERED } from 'react-transition-group/Transition';

const AbsoluteWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  ${(p) => (p.entered ? '' : 'overflow: hidden;')}

  display: flex;
  flex-direction: column;
`;

const DURATION = 200;
export const PageTransitionContext = React.createContext();

function PageTransition({ children }) {
  const router = useRouter();
  const containerRef = useRef();
  return (
    <TransitionGroup>
      <Transition
        key={router.pathname}
        timeout={DURATION}
        nodeRef={containerRef}
      >
        {(state) => (
          <PageTransitionContext.Provider value={state}>
            <AbsoluteWrapper ref={containerRef} entered={state == ENTERED}>
              {children}
            </AbsoluteWrapper>
          </PageTransitionContext.Provider>
        )}
      </Transition>
    </TransitionGroup>
  );
}

PageTransition.propTypes = {
  children: node.isRequired,
};

export default PageTransition;
