import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { ModalActions, ModalOverlay, PanelWrapper } from './styles';
import Bubble from '../bubble';
import Icon from '../icon';
import Panel from '../panel';

interface ModalProps {
  open?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
}

function Modal({ open = false, onClose, children }: ModalProps) {
  const overlayRef = useRef<HTMLDivElement>(null);

  return (
    <CSSTransition in={open} unmountOnExit timeout={200} nodeRef={overlayRef}>
      <ModalOverlay ref={overlayRef}>
        {onClose && (
          <ModalActions>
            <Bubble
              width="84px"
              shadow
              onClick={() => onClose()}
              label={<Icon icon="arrow-left" />}
            />
          </ModalActions>
        )}
        <PanelWrapper>
          <Panel>{children}</Panel>
        </PanelWrapper>
      </ModalOverlay>
    </CSSTransition>
  );
}

export default Modal;
