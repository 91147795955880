import { useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import { useContext } from 'react';

import appClient, { adminClient } from '~/shared/api/client';
import { ROLE_STUDENT } from '~/shared/config/hierarchy';

import { AuthContext } from './auth.context';
import {
  AdminLoginAsStudentMutationData,
  AdminLoginAsStudentOperation,
  AuthenticationPayloadFragment,
  LoginFromHomeMutationData,
  LoginFromHomeOperation,
  LoginMutationData,
  LoginOperation,
  ResetPasswordMutationData,
  StudentAuthPayloadFragment,
} from './login.app.generated';

export function useLogout({ redirectTo }: { redirectTo?: string } = {}) {
  const router = useRouter();
  const { setUser, setTokenInfo } = useContext(AuthContext);

  return {
    logout: () => {
      setUser(null);
      setTokenInfo(null);

      appClient.clearStore();
      adminClient.clearStore();

      if (redirectTo) {
        router.replace(redirectTo);
      }
    },
  };
}

export function useLoginState() {
  const { user, isLoggedIn } = useContext(AuthContext);

  return { isLoggedIn, user };
}

type FormattedLoginResult = {
  tokenInfo: {
    accessToken: string;
    refreshToken: string;
    expiresIn: number;
    tokenType: string;
  };
  user:
    | AuthenticationPayloadFragment['user']
    | (StudentAuthPayloadFragment['user'] & {
        roles: [{ name: typeof ROLE_STUDENT }];
        previousUser: AuthenticationPayloadFragment['user'];
      });
};

function formatLoginResult(loginResult: {
  data: LoginMutationData | ResetPasswordMutationData;
}): FormattedLoginResult {
  const {
    accessToken,
    refreshToken,
    expiresIn,
    tokenType,
    user: loggedInUser,
  } = loginResult.data.login;

  return {
    tokenInfo: {
      accessToken,
      refreshToken,
      expiresIn,
      tokenType,
    },
    user: loggedInUser,
  };
}
function formatStudentLoginResult(
  loginResult: { data: AdminLoginAsStudentMutationData },
  previousUser: AuthenticationPayloadFragment['user'],
): FormattedLoginResult {
  const {
    accessToken,
    refreshToken,
    expiresIn,
    tokenType,
    user: loggedInStudent,
  } = loginResult.data.admin.loginAsStudent;

  return {
    tokenInfo: {
      accessToken,
      refreshToken,
      expiresIn,
      tokenType,
    },
    user: {
      // make sure the student role is assigned for permission checks
      roles: [{ name: ROLE_STUDENT }],
      ...loggedInStudent,
      previousUser,
    },
    // @todo fix typecast necessity
  } as FormattedLoginResult;
}
function formatHomeLoginResult(loginResult: {
  data: LoginFromHomeMutationData;
}): FormattedLoginResult {
  const {
    accessToken,
    refreshToken,
    expiresIn,
    tokenType,
    user: loggedInStudent,
  } = loginResult.data.loginAsStudent;

  return {
    tokenInfo: {
      accessToken,
      refreshToken,
      expiresIn,
      tokenType,
    },
    user: {
      // make sure the student role is assigned for permission checks
      roles: [{ name: ROLE_STUDENT }],
      ...loggedInStudent,
    },
  } as FormattedLoginResult;
}

type ResultFormatter = (
  user: unknown,
  previousUser?: FormattedLoginResult,
) => FormattedLoginResult;

export function useLogin({
  mutation = LoginOperation,
  formatResult = formatLoginResult as ResultFormatter,
}: {
  mutation?:
    | typeof LoginOperation
    | typeof LoginFromHomeOperation
    | typeof AdminLoginAsStudentOperation;
  formatResult?: (
    user: unknown,
    previousUser?: FormattedLoginResult,
  ) => FormattedLoginResult;
} = {}) {
  const [loginMutation, { loading, error }] = useMutation(mutation);
  const { user, setUser, isLoggedIn, setTokenInfo, setRedirecting } =
    useContext(AuthContext);

  const login = async ({
    password,
    redirectLoggedIn,
    ...variables
  }: {
    password?: string;
    redirectLoggedIn: (user: FormattedLoginResult['user']) => Promise<void>;
    [key: string]: unknown;
  }) => {
    if (loading) return false;

    try {
      const loginResult = await loginMutation({
        variables: {
          ...variables,
          password: password?.trim(),
        },
      });

      appClient.clearStore();
      adminClient.clearStore();

      if (!!loginResult.data) {
        const { tokenInfo, user: loggedInUser } = formatResult(
          loginResult,
          user,
        );
        if (loggedInUser) {
          setRedirecting(true);
          setTokenInfo(tokenInfo);
          setUser(loggedInUser);
          await redirectLoggedIn?.(loggedInUser);
          setRedirecting(false);
          return loggedInUser;
        } else {
          console.error('[Login] Mutation returned empty user');
        }
      } else {
        console.error('[Login] Mutation did not return any data');
      }
    } catch (e) {
      // do nothing, error is in apollo mutation result
      console.error('[Login] Mutation failed with error', e);
    }
    return false;
  };

  return {
    loading,
    error,
    user,
    isLoggedIn,
    login,
  };
}

export function useStudentLogin() {
  const { login, loading, error } = useLogin({
    mutation: AdminLoginAsStudentOperation,
    formatResult: formatStudentLoginResult as ResultFormatter,
  });

  return { loading, error, login };
}

export function useLoginFromHome() {
  const { login, loading, error } = useLogin({
    mutation: LoginFromHomeOperation,
    formatResult: formatHomeLoginResult as ResultFormatter,
  });

  return { loading, error, login };
}
