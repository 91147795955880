import { type Theme, css } from '@emotion/react';
import styled from '@emotion/styled';
import { rgba } from 'polished';

function getTime(msTime: number) {
  return (p: { speed?: number }) => `${msTime / (p.speed || 1)}ms`;
}

export const SplashWrapper = styled.div<{ speed?: number }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;

  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${(p) => p.theme.staticDir}images/splash/background_cloud.svg)
    center no-repeat ${(p) => p.theme.backgroundColor};
  background-size: 90% auto;

  &:before {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: radial-gradient(
      circle closest-side,
      ${(p) => rgba(p.theme.bright, 0.55)} 0%,
      ${(p) => rgba(p.theme.bright, 0)} 110%
    );
  }

  &.splash-appear:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: ${(p) => p.theme.bright};
    opacity: 1;
    z-index: 5;
  }
  &.splash-appear-active:after {
    opacity: 0;
    transition: opacity ${getTime(3000)} ${getTime(500)};
  }
  &.splash-enter {
    opacity: 0;
  }
  &.splash-enter-active {
    opacity: 1;
    transition: opacity ${getTime(200)};
  }
  &.splash-exit {
    opacity: 1;
    pointer-events: none;
  }
  &.splash-exit-active {
    opacity: 0;
    transition:
      opacity ${getTime(4000)},
      background-size ${getTime(4000)};
    pointer-events: none;
    background-size: 200% auto;
  }
`;

export const SplashLogo = styled.img<{ speed?: number }>`
  position: relative;
  display: block;
  margin: 20px;
  width: 60%;
  max-width: 690px;
  height: auto;

  margin: auto;

  .splash-appear & {
    opacity: 0;
    transform: scale(0.9) translateY(200px);
  }
  .splash-appear-active & {
    transform: scale(1);
    opacity: 1;
    transition:
      opacity ${getTime(3000)} ${getTime(1000)},
      transform ${getTime(3000)} ${getTime(1000)},
      z-index ${getTime(5000)};
  }
  .splash-exit & {
  }
  .splash-exit-active & {
    opacity: 0;
    transform: scale(0.9) translateY(-200px);
    transition:
      transform ${getTime(3000)},
      opacity ${getTime(3000)};
  }
  .splash-enter-done & {
    z-index: 2;
  }
`;

const cloudStyles = (p: { theme: Theme }) => css`
  position: absolute;
  width: 70%;
  padding-top: 45%;
  background: url(${p.theme.staticDir}images/splash/cloud.svg) center no-repeat;
  background-size: contain;
`;

export const SplashCloudLeft = styled.div<{ speed?: number }>`
  ${cloudStyles}

  right: 70%;
  bottom: 50%;
  transform: scaleX(-1);

  .splash-appear & {
    transform: scaleX(-1) translate(-100%, 10%);
  }
  .splash-appear-active & {
    transform: scaleX(-1);
    transition: transform ${getTime(5000)};
  }
  .splash-exit & {
    transform: scaleX(-1);
  }
  .splash-exit-active & {
    transition: transform ${getTime(5000)};
    transform: scaleX(-1) translate(100%, -10%);
  }
`;
export const SplashCloudRight = styled.div<{ speed?: number }>`
  ${cloudStyles}

  left: 63.75%;
  top: 45%;

  .splash-appear & {
    transform: translate(-100%, -10%);
  }
  .splash-appear-active & {
    transform: translate(0, 0);
    transition: transform ${getTime(5000)};
  }

  .splash-exit & {
  }
  .splash-exit-active & {
    transition: transform ${getTime(5000)};
    transform: translate(100%, 10%);
  }
`;
