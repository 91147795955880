import styled from '@emotion/styled';
import { node, oneOf } from 'prop-types';
import React, { CSSProperties } from 'react';

interface FormActionsProps {
  justify?: CSSProperties['justifyContent'];
  children?: React.ReactNode;
}

const StyledFormActions = styled.div<{
  justify?: CSSProperties['justifyContent'];
}>`
  display: flex;
  justify-content: ${(p) => p.justify || 'space-between'};
  align-items: center;
  margin: 1.375rem -5px 0;
`;
const ActionWrapper = styled.div`
  margin: 0 5px;
`;

function FormActions({ justify, children }: FormActionsProps) {
  return (
    <StyledFormActions justify={justify}>
      {React.Children.map(
        children,
        (child, index) =>
          child && (
            <ActionWrapper key={`form-action-${index}`}>{child}</ActionWrapper>
          ),
      )}
    </StyledFormActions>
  );
}

FormActions.propTypes = {
  children: node.isRequired,
  justify: oneOf(['flex-end', 'flex-start', 'space-between']),
};

export default FormActions;
