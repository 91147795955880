export const nl_BE = {
  outdated: {
    outdated: 'Je browser is verouderd',
    unsupported: 'Je browser wordt niet ondersteund',
    web: 'Update je browser om Fonemi optimaal te gebruiken.',
    googlePlay:
      'Installeer de laatste versie van Chrome via Google Play om Fonemi optimaal te gebruiken.',
    appStore:
      'Update iOS vanuit de Settings App om Fonemi optimaal te gebruiken.',
    url: 'https://browser-update.org/nl/',
    callToAction: 'Update mijn browser nu',
    close: 'Sluiten',
  },
  auth: {
    intro: {
      title: 'Waar gaat je kleuter oefenen?',
      intro:
        'Kies waar je kleuter zal oefenen, oefent hij in de klas dan log je in als leerkracht, oefent hij thuis dan log je in met de persoonlijke code die je kleuter kreeg van juf of meester.',
      button_school: 'Op school',
      button_home: 'Thuis',
    },
    permission_denied: {
      title: 'Geen toegang',
      intro:
        'Je hebt geen toegang tot deze pagina, log in om toegang te krijgen.',
      button_login: 'Inloggen',
    },
    login: {
      general: {
        page_title: 'Aanmelden',
        not_enough_credits:
          'Er zijn niet meer genoeg actieve credits om in te loggen.',
        password_login_failed:
          'Login mislukt, controleer je gegevens en probeer opnieuw.',
        login_failed_check_region:
          'Je bevindt je momenteel op fonemi.be, misschien ben je op zoek naar <a href="https://fonemi.nl/">fonemi.nl</a>?',
        student_login_failed: 'Login mislukt.',
        fields: {
          loginCode: {
            label: 'Persoonlijke login code',
            placeholder: 'vives-1234-4567',
          },
          email: {
            label: 'E-mailadres',
            placeholder: 'bv. leerkracht@schoolmail.be',
          },
          new_password: {
            label: 'Nieuw wachtwoord',
          },
          password: {
            label: 'Wachtwoord',
            placeholder: 'bv. ••••••••',
          },
          access_code: {
            label: 'Persoonlijke code',
            placeholder: 'ABCD-1234-1234',
          },
        },
      },
      school: {
        title: 'Op school oefenen',
        intro: 'Log in met je e-mailadres en paswoord om een klas te kiezen.',
      },
      home: {
        title: 'Thuis oefenen',
        intro:
          'Je kleuter kreeg een persoonlijke code van de juf of meester. Vul hieronder de code om in te loggen. Zo kan je bengel meteen weer aan de slag!',
      },
      forgot_password: {
        title: 'Wachtwoord vergeten?',
        intro:
          'Ben je je wachtwoord vergeten? Vraag hier een nieuw wachtwoord aan met je e-mail adres.',
        back_to_login: 'Terug naar login',
        button_request_reset: 'Aanvragen',
        error:
          'Aanvraag mislukt, controleer je e-mail adres en probeer opnieuw.',
        success: 'Er werd een reset-code gestuurd naar je e-mail adres.',
      },
      password_reset: {
        title: 'Nieuw wachtwoord',
        intro: 'Stel hier je nieuw wachtwoord in',
        back_to_login: 'Terug naar login',
        button_reset: 'Aanpassen',
        error: 'Aanpassen mislukt, probeer opnieuw.',
      },
      button_forgot_password: 'Wachtwoord vergeten?',
      button_login: 'Log in',
    },
  },
  general: {
    locale: 'BE',
    splash: {
      logo: 'Fonemi - spelen met klanken',
    },
    bol_footer: {
      label: 'Met liefde gemaakt door',
    },
    page_actions: {},
    user: {
      actions: {
        to_app: 'Naar app',
        to_admin: 'Naar beheer',
        view_profile: 'Mijn profiel',
        select_other_student: 'Kies andere kleuter',
        logout: 'Uitloggen',
      },
      select_other_student: {
        title: 'Kies een andere kleuter',
        subtitle:
          'Vul het wachtwoord van "{{firstName, toUpperCase}}" in om een andere kleuter te selecteren.',
      },
    },
    errors: {
      title: 'Oeps, er ging iets mis.',
    },
    suggested_location: {
      title: 'Verdwaald?',
      intro:
        'Hallo! Je bent momenteel op <strong>fonemi.be</strong>, maar we denken dat je je in <strong>Nederland</strong> bevind, klopt dit?',
      stay: 'Blijf op fonemi.be',
      leave: 'Ga naar fonemi.nl',
      otherDomain: 'https://app.fonemi.nl',
    },
  },
  select_class: {
    page_title: 'Kies een klas',
    title: 'Dag {{firstName, toUpperCase}},',
    subtitle: 'Met welke klas ga je deze keer aan de slag?',
    no_active_classgroups: 'Oeps, er werden geen actieve klasgroepen gevonden.',
  },
  select_student: {
    page_title: 'Kies een kleuter',
    subtitle: 'Selecteer de kleuter die zal oefenen.',
    noStudentsFound:
      'Oeps, er werden geen kleuters gevonden in deze klasgroep.',
    confirm: {
      title: 'Ben je zeker dat je de app  wil klaarzetten voor:',
      button_decline: 'Nee, kies andere',
      button_accept: 'Ja, start de app!',
    },
  },
  test_audio: {
    title: 'Belangrijk!',
    subtitle:
      'Zonder geluid kan de kleuter niet aan de slag met de oefeningen. Check je eens of alles werkt?',
    button_test_audio: 'Test geluid',
    button_continue: 'Ga verder',
  },
  admin: {
    student: {
      personal_login_code: 'Persoonlijke login code',
      accomplished_skills: 'Afgewerkte vaardigheden',
      accomplished_tests: 'Afgewerkte onderdelen',
      total_answer_time: 'Totale werktijd',
      test_results: 'Resultaten oefeningen',
      skill_answer_time: 'Totale werktijd voor vaardigheid',
      confirm_reset: {
        title: 'Zeker?',
        description:
          'Ben je zeker dat je deze vaardigheid wil resetten? Dit kan niet ongedaan gemaakt worden.',
        button_decline: 'Annuleren',
        button_accept: 'Ja, reset',
      },
    },
    results: {
      pretest: 'Pretest',
      test_results: 'Resultaten oefeningen',
      test_answer_time: 'Totale werktijd',
      sub_skill_category: 'Subdeelvaardigheid',
    },
  },
};
