import React from 'react';

import { STATIC_DIR } from '~/shared/config';

import { FienArm, FienBody, FienMask, FienWrapper } from './styles';

export const CLIP = 'clip';
export const FADE = 'fade';
export const OVERFLOW = 'overflow';
// export const CONTAIN = 'contain';

export interface FienZwaaitProps {
  // how big a wave should be displayed
  intensity?: number;
  mode?: typeof CLIP | typeof FADE | typeof OVERFLOW;
  width?: string;
  height?: string;
  maskProps?: { style?: React.CSSProperties };
  style?: React.CSSProperties;
  className?: string;
}

const FienZwaait: React.FC<FienZwaaitProps> = ({
  intensity = 0.5,
  mode = CLIP,
  width = '100%',
  height,
  maskProps,
  style,
  className,
}) => {
  return (
    <FienWrapper
      className={className}
      width={width}
      height={height}
      style={style}
    >
      <FienMask {...maskProps} fade={mode === FADE} clip={mode === CLIP}>
        <FienArm intensity={intensity}>
          <img src={`${STATIC_DIR}images/fien/wave-arm.png`} alt="" />
        </FienArm>
        <FienBody />
      </FienMask>
    </FienWrapper>
  );
};

export default FienZwaait;
