import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { fromPromise } from '@apollo/client/link/utils';

import { API_BASE_URL } from '~/shared/config';

import { REFRESH_TOKEN } from './queries/refresh-token';

export const TOKEN_STORAGE_KEY = 'vives-fonemi-token-info';
export const USER_STORAGE_KEY = 'vives-fonemi-user-info';
const refreshClient = new ApolloClient({
  cache: new InMemoryCache({
    resultCaching: false,
  }),
  link: new HttpLink({
    uri: API_BASE_URL,
  }),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
    mutate: {
      fetchPolicy: 'no-cache',
    },
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
  },
});

let tokenInfo = null;
export const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => {
    if (tokenInfo?.accessToken) {
      return {
        headers: {
          authorization: `Bearer ${tokenInfo.accessToken}`,
          ...headers,
        },
      };
    }
    return {};
  });
  return forward(operation);
});

function logoutAndRedirect() {
  localStorage.removeItem(TOKEN_STORAGE_KEY);
  localStorage.removeItem(USER_STORAGE_KEY);

  // NOTE: hard redirect, will cause page reload and clear the apollo `InMemory` cache by doing so.
  if (window) {
    if (window.location.href.includes('/admin')) {
      window.location.href = '/admin/';
    } else {
      window.location.href = '/login/';
    }
  }
}

// const authRetryLink = new RetryLink({attempts});
export const authErrorLink = onError(
  ({ graphQLErrors, forward, operation }) => {
    if (graphQLErrors?.find((error) => error.message === 'Unauthenticated.')) {
      if (!tokenInfo?.refreshToken) {
        console.error('No refresh token found, logging out.');
        logoutAndRedirect();
        return;
      }
      // eslint-disable-next-line consistent-return
      return fromPromise(
        refreshClient
          .mutate({
            mutation: REFRESH_TOKEN,
            variables: {
              token: tokenInfo.refreshToken,
            },
          })
          .then(({ data }) => {
            const { __typename, ...newTokenInfo } = data.refreshToken;
            localStorage.setItem(
              TOKEN_STORAGE_KEY,
              JSON.stringify(newTokenInfo),
            );
            tokenInfo = newTokenInfo;

            return tokenInfo;
          })
          .catch((reason) => {
            console.error(`Refresh failed, logging out`, reason);
            logoutAndRedirect();
          }),
      )
        .filter((value) => !!value)
        .flatMap((newTokenInfo) => {
          const oldHeaders = operation.getContext().headers;
          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: `Bearer ${newTokenInfo.accessToken}`,
            },
          });

          return forward(operation);
        });
    }
  },
);

export function storeTokenInfo(newTokenInfo) {
  tokenInfo = newTokenInfo;
}
