import dayjs from 'dayjs';
import dayjs_nl_nl from 'dayjs/locale/nl';
import dayjs_nl_be from 'dayjs/locale/nl-be';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { firstLetter, ucFirst } from '~/shared/utils/string';

import { nl_BE } from './nl/be';
import { nl_NL } from './nl/nl';
import { LOCALE as DEFAULT_LOCALE } from '../config';

dayjs.extend(utc);
dayjs.extend(timezone);

// Warning: when adding or removing languages also update `envForEnvironment.js`
// Not using next locale to prevent automatic redirects from NL to BE or vice versa
const resources = {
  'nl-BE': nl_BE,
  'nl-NL': nl_NL,
};

export type Locale = keyof typeof resources;

const FORMATTERS: Record<string, (value: string, lng?: Locale) => string> = {
  firstUpperCase: (value: string) => firstLetter(value)?.toUpperCase(),
  firstLetter,
  ucFirst,
  toUpperCase: (value: string) => value?.toUpperCase(),
  price: (value: string) => parseFloat(value).toString().replace('.', ','),
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: DEFAULT_LOCALE,
    fallbackLng: 'nl-BE',
    supportedLngs: ['nl-NL', 'nl-BE'],

    interpolation: {
      escapeValue: false, // react already safes from xss

      format(value, format, lng) {
        return format ? FORMATTERS[format]?.(value, lng as Locale) : value;
      },
    },
  });

const dayjsLocales = {
  'nl-BE': dayjs_nl_be,
  'nl-NL': dayjs_nl_nl,
};

export function setDayJsLocale(locale: Locale) {
  dayjs.locale(locale, dayjsLocales[locale] || dayjs_nl_be);
}

// NOTE: not using next-i18next because there is only one language, for now.
// Update app.getStaticProps when more languages are implemented
setDayJsLocale(DEFAULT_LOCALE);

export default i18n;
