import { useTheme } from '@emotion/react';
import outdatedBrowserRework from 'outdated-browser-rework';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function OutdatedContainer() {
  const theme = useTheme();
  const { t } = useTranslation('outdated');
  useEffect(() => {
    document.getElementById('outdated').style.display = 'none';
    outdatedBrowserRework({
      language: 'nl',
      fullscreen: false,
      backgroundColor: theme.warning,
      textColor: '#000',
      browserSupport: {
        Chrome: 60, // Includes Chrome for mobile devices
        Edge: 41,
        Safari: 11,
        'Mobile Safari': 11,
        Firefox: 55,
        Opera: 50,
        Vivaldi: 1,
        // You could specify minor version too for those browsers that need it.
        Yandex: { major: 17, minor: 10 },
        // You could specify a version here if you still support IE in 2017.
        // You could also instead seriously consider what you're doing with your time and budget
        IE: false,
      },
      messages: {
        nl: {
          outOfDate: t('outdated'),
          unsupported: t('unsupported'),
          update: {
            web: t('web'),
            googlePlay: t('googlePlay'),
            appStore: t('appStore'),
          },
          // You can set the URL to null if you do not want a clickable link or provide
          // your own markup in the `update.web` message.
          url: t('url'),
          callToAction: t('callToAction'),
          close: t('close'),
        },
      },
    });
  }, []);

  return (
    <div id="outdated">
      <div className="vertical-center">
        <h6>{t('outdated')}</h6>
        <p>{t('web')}</p>
      </div>
    </div>
  );
}

export default OutdatedContainer;
