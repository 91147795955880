import { node } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import Modal from '~/shared/ui/modal';

import TestAudioModal from './test-audio.modal';

export const CanPlayAudioContext = React.createContext({
  canPlay: false,
  hasInteracted: false,
  hasShownModal: false,
  showModal: false,
  requestAudio: () => {},
  dismiss: () => {},
});

export function CanPlayAudioContextProvider({ children }) {
  const [hasInteracted, setHasInteracted] = useState(false);
  const [{ show: showModal, hasShown: hasShownModal }, setModalState] =
    useState(false);

  const requestAudio = useCallback(() => {
    if (!hasInteracted || !hasShownModal) {
      setTimeout(() => {
        setModalState((current) => {
          if (!current.hasShown) {
            return {
              hasShown: true,
              show: true,
            };
          }
          return current;
        });
      }, 200);
    }
  }, [hasInteracted, hasShownModal]);

  const dismiss = useCallback(() => {
    setHasInteracted(true);
    setModalState({
      show: false,
      hasShown: true,
    });
  }, []);

  useEffect(() => {
    if (!hasInteracted) {
      const interactionHandler = () => {
        setHasInteracted(true);
      };

      window.addEventListener('click', interactionHandler);
      window.addEventListener('touchstart', interactionHandler);

      return () => {
        window.removeEventListener('click', interactionHandler);
        window.removeEventListener('touchstart', interactionHandler);
      };
    }
    return () => {};
  }, [hasInteracted]);

  useEffect(() => {
    const visibilityChangeHandler = () => {
      if (document.visibilityState === 'hidden' || document.hidden) {
        setHasInteracted(false);
        setModalState({
          hasShown: false,
          show: false,
        });
      }
    };

    document.addEventListener('visibilitychange', visibilityChangeHandler);
    return () => {
      document.removeEventListener('visibilitychange', visibilityChangeHandler);
    };
  }, []);

  return (
    <CanPlayAudioContext.Provider
      value={{
        hasInteracted,
        hasShownModal,
        requestAudio,
        dismiss,
        canPlay: hasInteracted && !showModal,
      }}
    >
      <TestAudioModal
        Wrapper={Modal}
        open={showModal}
        onDismiss={dismiss}
        delay={200}
      />
      {children}
    </CanPlayAudioContext.Provider>
  );
}
CanPlayAudioContextProvider.propTypes = {
  children: node.isRequired,
};
