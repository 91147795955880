import { useTheme } from '@emotion/react';
import React, { useMemo } from 'react';

import {
  BubbleLabel,
  StyledBubble,
  StyledBubbleButton,
  useBubbleColor,
} from './bubble.styles';

interface BubbleProps {
  className?: string;
  label?: React.ReactNode;
  generateColorByValue?: string;
  image?: string;
  width?: string;
  shadow?: boolean;
  onClick?: () => void;
}

let imageId = 0;

function Bubble({
  label,
  generateColorByValue,
  image,
  width = '70px',
  shadow,
  ...props
}: BubbleProps) {
  const Wrapper = props.onClick ? StyledBubbleButton : StyledBubble;
  const { background, label: labelColor } = useBubbleColor(
    generateColorByValue || '',
  );
  const svgImagePatternId = useMemo(() => {
    imageId += 1;
    return `svg-bubble-image-${imageId}`;
  }, [!!image]);
  const theme = useTheme();

  return (
    <Wrapper width={width} {...props}>
      <svg
        width="86"
        height="78"
        viewBox="0 0 86 78"
        fill={image ? `url(#${svgImagePatternId})` : background}
        xmlns="http://www.w3.org/2000/svg"
      >
        {image && (
          <defs>
            <pattern
              id={svgImagePatternId}
              height="100%"
              width="100%"
              patternContentUnits="objectBoundingBox"
            >
              <image
                height="1"
                width="1"
                preserveAspectRatio="xMidYMid slice"
                xlinkHref={image}
              />
            </pattern>
          </defs>
        )}
        {shadow && (
          <path
            d="M18.0728 6.98708C-21.7775 35.9587 11.1234 94.0411 62.8939 70.5248C114.664 47.0086 57.9231 -21.9845 18.0728 6.98708Z"
            fill={theme.shadowColor}
            transform="translate(2,2)"
          />
        )}
        <path
          d="M18.0728 6.98708C-21.7775 35.9587 11.1234 94.0411 62.8939 70.5248C114.664 47.0086 57.9231 -21.9845 18.0728 6.98708Z"
          fill={image ? `url(#${svgImagePatternId})` : undefined}
        />
      </svg>
      {label && !image && (
        <BubbleLabel
          color={labelColor}
          style={{
            fontSize: `calc(max(12px, ${width} / 3.5))`,
            lineHeight: `calc(${width} * 1.1)`,
          }}
        >
          {label}
        </BubbleLabel>
      )}
    </Wrapper>
  );
}

export default Bubble;
