import React, { useState } from 'react';

import { StyledFormGroup, StyledLabel } from './styles';

let suffix = 0;
function useUniqueId(id?: string) {
  const [uniqueId] = useState(() => {
    suffix += 1;
    return id || `form-input-${suffix}`;
  });

  return uniqueId;
}

interface FormGroupProps {
  id?: string;
  label?: string;
  children: React.ReactNode;
}

const FormGroup: React.FC<FormGroupProps> = ({ id, label, children }) => {
  const childId = React.Children.map(children, (child) =>
    child ? (child as any).props.id : undefined,
  )?.[0];
  const itemId = useUniqueId(id || childId);
  return (
    <StyledFormGroup>
      {label && <StyledLabel htmlFor={itemId}>{label}</StyledLabel>}
      {React.Children.map(children, (child, i) => {
        if (i === 0) {
          return React.cloneElement(child as React.ReactElement, {
            id: itemId,
          });
        }
        return child;
      })}
    </StyledFormGroup>
  );
};

export default FormGroup;
