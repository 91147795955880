import styled from '@emotion/styled';

export const StyledBolFooter = styled.div<{
  transparent?: boolean;
  fixed?: boolean;
}>`
  min-height: 35px;
  text-align: center;
  align-self: stretch;
  margin-block-start: 40px;

  background-color: ${(p) =>
    p.transparent ? 'transparent' : p.theme.bolFooterBg};
  position: ${(p) => (p.fixed ? 'fixed' : 'absolute')};
  bottom: 0;
  width: 100%;

  a,
  span {
    font-size: 0.75rem;
    line-height: 1;

    display: inline-block;

    padding: 10px 0;

    text-decoration: none;

    color: ${(p) => (p.transparent ? '#fff' : p.theme.bolFooterColor)};
    border: 0px none;
  }

  .bol-footer-heart {
    position: relative;

    display: inline-block;

    width: 14px;
    height: 12px;
    margin-right: 2px;
    margin-left: 3px;

    > div {
      position: absolute;
      bottom: 0;

      width: 1px;
      margin-right: 1px;

      transition-timing-function: 'ease-out';
      transition-duration: 0.3s;
      transition-property: height, bottom;

      background-color: ${(p) =>
        p.transparent ? '#fff' : p.theme.bolFooterGraphColor};

      &.bar1 {
        height: 3px;
      }

      &.bar2 {
        left: 2px;

        height: 8px;
      }

      &.bar3 {
        left: 4px;

        height: 9px;
      }

      &.bar4 {
        left: 6px;

        height: 5px;
      }

      &.bar5 {
        left: 8px;

        height: 6px;
      }

      &.bar6 {
        left: 10px;

        height: 10px;
      }

      &.bar7 {
        left: 12px;

        height: 3px;
      }
    }
  }

  a:hover .bol-footer-heart {
    > div {
      &.bar1 {
        bottom: 6px;

        height: 3px;
      }

      &.bar2 {
        bottom: 4px;

        height: 7px;
      }

      &.bar3 {
        bottom: 2px;

        height: 8px;
      }

      &.bar4 {
        bottom: 0;

        height: 8px;
      }

      &.bar5 {
        bottom: 2px;

        height: 8px;
      }

      &.bar6 {
        bottom: 4px;

        height: 7px;
      }

      &.bar7 {
        bottom: 6px;

        height: 3px;
      }
    }
  }
`;

export default StyledBolFooter;
