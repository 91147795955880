import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

import { STATIC_DIR } from '~/shared/config';

import {
  SplashCloudLeft,
  SplashCloudRight,
  SplashLogo,
  SplashWrapper,
} from './styles';

interface SplashScreenProps {
  onComplete?: () => void;
  // speed multiplier for the base timings, eg. 5s/speed
  speed?: number;
  // duration of the pause in ms
  pauseDuration?: number;
}

function SplashScreen({
  onComplete,
  pauseDuration = 1000,
  speed = 1,
}: SplashScreenProps) {
  const [visible, setVisible] = useState(true);
  const [entered, setEntered] = useState(false);
  const { t } = useTranslation('general');

  useEffect(() => {
    if (entered) {
      const timeout = setTimeout(() => {
        setVisible(false);
      }, pauseDuration);

      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [entered, pauseDuration]);

  return (
    <CSSTransition
      in={visible}
      appear
      unmountOnExit
      mountOnEnter
      timeout={5000 / speed}
      classNames="splash"
      onEntered={() => setEntered(true)}
      onExited={onComplete}
    >
      <SplashWrapper speed={speed}>
        <SplashLogo
          src={`${STATIC_DIR}images/splash/fonemi-logo-full.png`}
          alt={t('splash.logo')}
          speed={speed}
        />
        <SplashCloudLeft speed={speed} />
        <SplashCloudRight speed={speed} />
      </SplashWrapper>
    </CSSTransition>
  );
}

export default SplashScreen;
