import styled from '@emotion/styled';

export const StyledFormGroup = styled.div`
  margin-bottom: 0.875rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`;
export const StyledLabel = styled.label`
  display: block;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
  color: ${(p) => p.theme.dark500};
`;
