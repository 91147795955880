import { useRouter } from 'next/router';
import { bool, func, shape, string } from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  TOKEN_STORAGE_KEY,
  USER_STORAGE_KEY,
  storeTokenInfo,
} from '~/shared/api/auth';
import {
  ROLE_STUDENT,
  hasAnyAdminRole,
  hasRequiredRole,
} from '~/shared/config/hierarchy';
import usePersistantState from '~/shared/hooks/usePersistantState';

export const AuthContext = React.createContext();

export const AUTH_STATE = {
  LOGGED_IN: true,
  LOGGED_OUT: false,
  UNSURE: undefined,
};

export function AuthContextProvider({ children, pageOptions }) {
  const router = useRouter();
  const { isPublic, isAdmin, isLogin, requiredRole } = pageOptions;
  const [isRedirecting, setRedirecting] = useState(false);

  // @todo refactor auth context: use zustand store (easier to maintain), convert expiresIn to local date and check before executing API calls, ...
  const [tokenInfo, setTokenInfo] = usePersistantState(
    TOKEN_STORAGE_KEY,
    storeTokenInfo,
  );
  const [user, setUser] = usePersistantState(USER_STORAGE_KEY);
  let isLoggedIn = !!tokenInfo?.accessToken;
  if (isRedirecting || tokenInfo === undefined) {
    isLoggedIn = AUTH_STATE.UNSURE;
  }

  const hasAccess = isPublic || hasRequiredRole(user, requiredRole || []);
  const targetRoute = useMemo(() => {
    if (isRedirecting || isLoggedIn === AUTH_STATE.UNSURE) {
      return;
    }
    if (hasAccess || isPublic) {
      if (isLoggedIn && isLogin) {
        if (hasRequiredRole(user, ROLE_STUDENT)) {
          return '/test/';
        }
        if (hasAnyAdminRole(user)) {
          return '/login/select-class/';
        }
      }
      return;
    }

    if (requiredRole && !hasRequiredRole(user, requiredRole)) {
      if (hasAnyAdminRole(user)) {
        if (isAdmin) {
          return '/admin/';
        } else {
          return '/login/select-class/';
        }
      } else if (hasRequiredRole(user, ROLE_STUDENT)) {
        return '/test/';
      } else if (isAdmin) {
        return '/admin/';
      } else {
        return '/';
      }
    }
  }, [
    hasAccess,
    isRedirecting,
    isPublic,
    requiredRole,
    user,
    isLoggedIn,
    isAdmin,
    isLogin,
  ]);

  const routerRef = useRef(router);
  routerRef.current = router;
  useEffect(() => {
    const effectRouter = routerRef.current;
    if (targetRoute && effectRouter && targetRoute !== effectRouter.asPath) {
      setRedirecting(true);
      effectRouter
        .replace(targetRoute)
        .then(() => setRedirecting(false))
        .catch(() => {
          setRedirecting(false);
        });
    }
  }, [targetRoute]);

  if (!isPublic && isLoggedIn === AUTH_STATE.UNSURE) {
    return 'Loading...';
  }

  if (isRedirecting) {
    return 'Redirecting...';
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        isLoggedIn,
        isRedirecting,
        tokenInfo,
        setTokenInfo,
        setRedirecting,
      }}
    >
      {children(hasAccess)}
    </AuthContext.Provider>
  );
}
AuthContextProvider.propTypes = {
  children: func.isRequired,
  pageOptions: shape({
    isPublic: bool,
    requiredRole: string,
  }),
};
AuthContextProvider.defaultProps = {
  pageOptions: {},
};
