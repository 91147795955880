import { ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

const ProductionErrorLogLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    try {
      if (networkError) {
        console.warn(
          `Apollo error::${operation.operationName}:: ${networkError.message}`,
          {
            error: networkError,
            operation,
          },
        );
      }
      graphQLErrors?.forEach((error) => {
        console.warn(
          `Apollo error::${operation.operationName}:: ${error.message}`,
          {
            error,
            operation,
          },
        );
      });
    } catch (e) {}

    return forward(operation);
  },
);

const ErrorLogLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    console.groupCollapsed(
      `%c ⚠ GraphQL Request: ${operation.operationName}`,
      'color: #f33;',
    );
    console.log('Query', operation.query?.loc?.source);
    console.log('Variables', operation.variables);
    console.log('Headers', operation.getContext().headers);
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        console.group(`Error ${error.message}`);
        console.log(error);
        console.groupEnd();
      });
    }
    if (networkError) {
      console.group(`Network error ${networkError.message}`);
      console.log(networkError);
      console.groupEnd();
    }
    console.groupEnd();
    return forward(operation);
  },
);

const ResponseLogLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    console.groupCollapsed(
      `%c GraphQL Request: ${operation.operationName}`,
      'color: #f0f;',
    );
    console.log('Query', operation.query?.loc?.source);
    console.log('Variables', operation.variables);
    console.log('Headers', operation.getContext().headers);
    console.log('Response', response);
    console.log('All', { all: { operation, response } });
    console.groupEnd();
    return response;
  });
});

const appolloRequestLoggerLinks: ApolloLink[] = [];
// if (process.env.NODE_ENV !== 'production') {
//   appolloRequestLoggerLinks.push(ErrorLogLink, ResponseLogLink);
// } else {
appolloRequestLoggerLinks.push(ProductionErrorLogLink);
// }

export default appolloRequestLoggerLinks;
