import { Howl } from 'howler';

/**
 *
 * @param src
 * @param callback on sound complete, failed, ended or interrupted
 * @returns Howl
 */
export default function playSingleAudioFile(
  src: string,
  callback?: () => void,
) {
  let resolved = false;
  let onVisibilityChange: () => void;
  const innerResolve = () => {
    if (!resolved) {
      callback?.();
      resolved = true;

      if (onVisibilityChange) {
        document.removeEventListener('visibilitychange', onVisibilityChange);
      }
    }
  };

  const sound = new Howl({
    src,
    autoplay: true,
    onend: innerResolve,
    onstop: innerResolve,
    onloaderror: (error) => {
      console.error('[playSingleAudioFile] load error', error);
      innerResolve();
    },
    onplayerror: (error) => {
      console.error('[playSingleAudioFile] play error', error);
      innerResolve();
    },
  });

  onVisibilityChange = () => {
    if (document.visibilityState === 'hidden' || document.hidden) {
      sound.stop();
    }
  };

  document.addEventListener('visibilitychange', onVisibilityChange);

  return sound;
}
