import { type Theme, css, keyframes } from '@emotion/react';
import emotionReset from 'emotion-reset';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const globalStyles = (p: { theme: Theme }) => css`
  ${emotionReset}
  *, *::after, *::before {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
  }

  html,
  body {
    font-family: 'Open Sans', sans-serif;
    color: ${p.theme.dark500};
    font-size: 16px;
    line-height: 1.5625;
  }
  body {
    background: ${p.theme.backgroundColor} fixed;
    transition: background-color 0.5s;

    ${p.theme.backgroundImage
      ? `background-image: url(${p.theme.backgroundImage})`
      : ''}
  }
  body.landing-page {
    background: white;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5 {
    font-weight: 700;
    color: ${p.theme.dark200};
  }
  h1,
  .h1 {
    font-size: 2rem;
  }
  h2,
  .h2 {
    font-size: 1.8rem;
  }
  h3,
  .h3 {
    font-size: 1.6rem;
  }
  h4,
  .h4 {
    font-size: 1.3rem;
  }
  h5,
  .h5 {
    font-size: 1rem;
  }

  h6,
  .h6 {
    font-weight: normal;
    text-transform: uppercase;
    color: ${p.theme.dark200};
    font-size: 0.8rem;
  }

  strong,
  b {
    font-weight: 700;
  }
  em,
  i {
    font-style: italic;
  }

  :focus {
    outline-color: white;
    /* outline-radius: 10px; */
  }

  a {
    color: inherit;
  }

  #outdated {
    position: fixed;
    display: table;
    overflow: hidden;
    left: 0;
    text-align: center;
    text-transform: uppercase;
    top: 0;
    width: 100%;
    z-index: 9999;
    padding: 0 24px 24px 0;
    background-color: ${p.theme.warning};
    color: black;
    /* prevent modern browsers from flashing this message */
    animation: ${fadeIn} 0.3s 0.5s both;

    &.fullscreen {
      height: 100%;
    }
    .vertical-center {
      display: table-cell;
      text-align: center;
      vertical-align: middle;
    }
    h6 {
      font-size: 25px;
      line-height: 25px;
      margin: 12px 0;
      color: black;
    }

    p {
      font-size: 12px;
      line-height: 12px;
      margin: 0;
      color: black;
    }

    #buttonUpdateBrowser {
      border: 2px solid;
      /* color: $secondaryColor; */
      cursor: pointer;
      display: block;
      margin: 30px auto 0;
      padding: 10px 20px;
      position: relative;
      text-decoration: none;
      width: 230px;
      &:hover {
        /* background-color:$secondaryColor; */
        /* color: $primaryColor; */
      }
    }

    .last {
      height: 20px;
      position: absolute;
      right: 70px;
      top: 10px;
      width: auto;
      display: inline-table;
    }

    .last[dir='rtl'] {
      left: 25px !important;
      right: auto !important;
    }

    #buttonCloseUpdateBrowser {
      /* color: $secondaryColor; */
      display: block;
      font-size: 36px;
      height: 100%;
      line-height: 36px;
      position: relative;
      text-decoration: none;
      width: 100%;
    }
  }
`;

export default globalStyles;
