// nl_BE is the default and fallback language, these are overrides
export const nl_NL = {
  general: {
    locale: 'NL',
    suggested_location: {
      title: 'Verdwaald?',
      intro:
        'Hallo! Je bent momenteel op <strong>fonemi.nl</strong>, maar we denken dat je je in <strong>België</strong> bevind, klopt dit?',
      stay: 'Blijf op fonemi.nl',
      leave: 'Ga naar fonemi.be',
      otherDomain: 'https://app.fonemi.be',
    },
  },
  auth: {
    login: {
      general: {
        login_failed_check_region:
          'Je bevindt je momenteel op fonemi.nl, misschien ben je op zoek naar <a href="https://fonemi.be/">fonemi.be</a>?',
      },
    },
  },
};
