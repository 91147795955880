import { type Theme } from '@emotion/react';
import { rgba, shade, tint } from 'polished';

import { STATIC_DIR } from '~/shared/config';

const GENERIC_COLORS = {
  red: '#C21F17',
  orange: '#F3AE4B',
  pink: '#DE4383',
  purple: '#90599E',
  blue: '#00A8B5',
  green: '#519629',
  lightBlue: '#F4F7FD',
} as const;

// for "generated" color scales like avatar-bubble colors (string to number as index of this array)
const COLOR_SCALE = [
  '#F3AE4B',
  '#DE4383',
  '#90599E',
  '#00A8B5',
  '#519629',
  '#7E9DF3',
];

export const BREAKPOINTS = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  EXTRA_LARGE: 'extraLarge',
} as const;

const THEME: Theme = {
  staticDir: STATIC_DIR,
  bolFooterBg: '#FFF',
  bolFooterColor: '#8F949F',
  bolFooterGraphColor: '#7E9DF3', // brand

  backgroundColor: '#A6E3E8',
  backgroundImage: `${STATIC_DIR}images/cloud-pattern.png`,
  brand: '#7E9DF3',
  brandDarker100: shade(0.1, '#7E9DF3'),
  brandLighter100: tint(0.1, '#7E9DF3'),

  secondary: '#A6E3E8',
  secondaryDarker100: '#86CBD0',
  secondaryDarker200: '#74B7BC',

  tertiary: '#A6E3E8',
  tertiaryDarker100: '#86CBD0',
  tertiaryDarker200: '#74B7BC',

  dark200: '#4F5871',
  dark400: '#7F8EB9',
  dark400a50: rgba('#7F8EB9', 0.5),
  dark500: '#8F949F',
  dark500a50: rgba('#8F949F', 0.5),

  bright: '#FFFFFF',
  bright100: '#ECF4F4',
  bright200: shade(0.1, '#ECF4F4'),

  positive: '#519629',
  warning: '#F3AE4B',
  negative: '#C21F17',
  error: '#C21F17',
  positive100: tint(0.9, '#519629'),
  warning100: tint(0.9, '#F3AE4B'),
  negative100: tint(0.9, '#C21F17'),
  error100: tint(0.9, '#C21F17'),
  info: GENERIC_COLORS.blue,

  colors: GENERIC_COLORS,
  colorScale: COLOR_SCALE,
  shadowColor: 'rgba(10, 58, 62, 0.1)',

  shadows: {
    small: '2px 4px 0 rgba(10, 58, 62, 0.1)',
    medium: '4px 6px 0 rgba(10, 58, 62, 0.1)',
    soft: '0px 0px 12px rgba(0, 0, 0, 0.25)',
  },

  breakpoints: {
    [BREAKPOINTS.SMALL]: '(min-width: 32rem)',
    [BREAKPOINTS.MEDIUM]: '(min-width: 48rem)',
    [BREAKPOINTS.LARGE]: '(min-width: 64rem)',
    [BREAKPOINTS.EXTRA_LARGE]: '(min-width: 80rem)',
  },
};

export default THEME;

export const ORANGE_THEME: Theme = {
  ...THEME,

  bolFooterGraphColor: '#D37218', // secondaryDarker100
  backgroundColor: '#E2870E',
  backgroundImage: null,
  secondary: '#E2870E',
  secondaryDarker100: '#D37218',
  secondaryDarker200: shade(0.1, '#D37218'),
};

export const GREEN_THEME: Theme = {
  ...THEME,

  bolFooterGraphColor: '#519629', // secondaryDarker100
  backgroundColor: '#6CB442',
  backgroundImage: null,
  secondary: '#6CB442',
  secondaryDarker100: '#519629',
  secondaryDarker200: shade(0.1, '#519629'),
};

export const PURPLE_THEME: Theme = {
  ...THEME,

  bolFooterGraphColor: '#8727C4', // secondaryDarker100
  backgroundColor: '#9C3BDA',
  backgroundImage: null,
  secondary: '#9C3BDA',
  secondaryDarker100: '#8727C4',
  secondaryDarker200: shade(0.1, '#8727C4'),
};
