export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AuthorizableUnion: ['Student', 'User'],
    IAuthenticationPayload: [
      'AuthenticationPayload',
      'StudentAuthenticationPayload',
    ],
    SkillTestSetUnion: ['SkillTest', 'SubSkillTestSet'],
    SubSkillTestUnion: ['SubSkillPreTest', 'SubSkillTest'],
  },
};
export default result;
