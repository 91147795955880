import styled from '@emotion/styled';
import Link from 'next/link';
import { bool, node, string } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { STATIC_DIR } from '~/shared/config';
import { breakpoint } from '~/shared/styles/utils';
import Bubble from '~/shared/ui/bubble';
import Icon from '~/shared/ui/icon';
import Panel from '~/shared/ui/panel';

import BaseLayout from './BaseLayout';

const Content = styled.div`
  margin: auto;
  width: 100%;
  padding: 10px;

  ${breakpoint('small')} {
    padding: 20px;
  }
  ${breakpoint('medium')} {
    max-width: 600px;
  }
`;

const LogoTitle = styled.h1`
  position: relative;
  margin: -45% auto 20px;
  width: 85%;

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  .logo-location-bubble {
    position: absolute;
    bottom: 42%;
    left: 78%;
    transform: rotate(10deg);
  }

  ${breakpoint('medium')} {
    margin-bottom: 30px;
  }
`;

const titleImage = `${STATIC_DIR}images/fonemi-logo-login.png`;

function AuthLayout({ back, children, showTitle, ...props }) {
  const { t, i18n } = useTranslation('general');
  const pageActions =
    back &&
    (typeof back === 'function' ? (
      <Bubble shadow label={<Icon icon="arrow-left" />} onClick={back} />
    ) : (
      <Link href={back}>
        <Bubble shadow label={<Icon icon="arrow-left" />} />
      </Link>
    ));

  return (
    <BaseLayout pageActions={pageActions} {...props}>
      <Content>
        <Panel style={{ position: 'relative' }}>
          {showTitle && (
            <LogoTitle>
              <img src={titleImage} alt="Fonemi" />

              <Bubble
                className="logo-location-bubble"
                label={t('locale')}
                width="45px"
                generateColorByValue={i18n.language}
              />
            </LogoTitle>
          )}
          {children}
        </Panel>
      </Content>
    </BaseLayout>
  );
}
AuthLayout.propTypes = {
  back: string,
  showTitle: bool,
  children: node.isRequired,
};
AuthLayout.defaultProps = {
  back: null,
  showTitle: true,
};

export default AuthLayout;
