import { node } from 'prop-types';
import React, { useContext } from 'react';

import { PageTransitionContext } from './PageTransition';

const TIMEOUT = 200;

const getTransitionStyles = {
  entering: {
    opacity: 0,
    // transform: `translateY(50px)`,
  },
  entered: {
    transition: `opacity ${TIMEOUT}ms, transform ${TIMEOUT}ms`,
    opacity: 1,
    // transform: `translateY(0px)`,
  },
  exiting: {
    transition: `opacity ${TIMEOUT}ms, transform ${TIMEOUT}ms`,
    opacity: 0,
    // transform: `translateY(-50px)`,
  },
};

function PageContentTransition({ children }) {
  const status = useContext(PageTransitionContext);
  const child = React.Children.only(children);

  return React.cloneElement(child, {
    className: `transition-${status}`,
    style: {
      width: '100%',
      ...getTransitionStyles[status],
    },
  });
}

PageContentTransition.propTypes = {
  children: node.isRequired,
};

export default PageContentTransition;
