import { ApolloProvider } from '@apollo/client/react';
import { Global, ThemeProvider } from '@emotion/react';
import { Provider as RollbarProvider } from '@rollbar/react';
import App from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';
import Script from 'next/script';
import { DefaultSeo } from 'next-seo';
import React from 'react';

import '~/shared/i18n';
import OutdatedContainer from '~/features/Outdated';
import { AuthContextProvider } from '~/features/auth';
import PermissionDenied from '~/features/auth/PermissionDenied';
import PageTransition from '~/features/layouts/PageTransition';
import { LocationGuesser } from '~/features/location-guesser';
import appClient from '~/shared/api/client';
import { CanPlayAudioContextProvider } from '~/shared/audio';
import { FeedbackAudioProvider } from '~/shared/audio/feedback';
import { BASE_URL, BUILD_INFO, ENVIRONMENT, STATIC_DIR } from '~/shared/config';
import { ROLE_STUDENT } from '~/shared/config/hierarchy';
import { CharacterProvider } from '~/shared/context/Character';
import globalStyles from '~/shared/styles/global';
import defaultTheme from '~/shared/styles/theme';
import SplashScreen from '~/shared/ui/splash-screen';
import env from '~/shared/utils/env';

const { publicRuntimeConfig } = getConfig();
/** @type {import('rollbar').Configuration} */
const rollbarConfig = {
  accessToken: publicRuntimeConfig.rollbarClientToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: `${ENVIRONMENT}`,
    client: {
      javascript: {
        code_version: BUILD_INFO.version.pkg,
        source_map_enabled: true,
      },
    },
  },
};

export default class MyApp extends App {
  constructor(props) {
    super(props);
    const { Component } = props;
    const pageOptions = Component.options || {};

    this.state = {
      waitForSplash: pageOptions.showSplash,
    };
  }

  componentDidMount() {
    Howler.autoSuspend = false;
  }

  render() {
    const { Component, router, pageProps } = this.props;
    const { waitForSplash } = this.state;
    const pageOptions = { ...pageProps, ...Component.options };
    const { theme = defaultTheme, withTransition = true } = pageOptions;
    const PageWrapper = withTransition ? PageTransition : React.Fragment;

    return (
      <>
        <Head>
          <meta
            name="viewport"
            content="initial-scale=1.0, maximum-scale=1.0, width=device-width, user-scalable=no"
          />
        </Head>
        {publicRuntimeConfig.userbackAccessToken && (
          <Script id="userback">
            {`
            window.Userback = window.Userback || {};
            Userback.access_token = "${publicRuntimeConfig.userbackAccessToken}";
            Userback.native_screenshot = true;
            (function(d) {
                var s = d.createElement('script');s.async = true;
                s.src = 'https://static.userback.io/widget/v1.js';
                (d.head || d.body).appendChild(s);
            })(document);
            `}
          </Script>
        )}

        <DefaultSeo
          // title="Fonemi"
          titleTemplate="Fonemi | %s"
          defaultTitle="Fonemi"
          noindex={!env('production')}
          nofollow={!env('production')}
          openGraph={{
            url: `${BASE_URL}${router.asPath}`,
            images: [
              {
                url: `${STATIC_DIR}images/fb-img-fonemi.jpg`,
                width: 1200,
                height: 630,
              },
            ],
          }}
        />
        <ThemeProvider theme={theme}>
          <>
            <Global styles={globalStyles({ theme })} />
            <OutdatedContainer />

            <RollbarProvider config={rollbarConfig}>
              <CharacterProvider>
                <ApolloProvider client={appClient}>
                  <AuthContextProvider pageOptions={pageOptions}>
                    {(hasAccess) => (
                      <>
                        <FeedbackAudioProvider
                          active={
                            hasAccess &&
                            pageOptions.requiredRole === ROLE_STUDENT
                          }
                        >
                          <CanPlayAudioContextProvider>
                            {hasAccess ? (
                              <PageWrapper>
                                <Component {...pageProps} />
                              </PageWrapper>
                            ) : (
                              <PermissionDenied />
                            )}
                          </CanPlayAudioContextProvider>
                        </FeedbackAudioProvider>

                        <LocationGuesser
                          active={pageOptions.isPublic || !hasAccess || false}
                        />
                      </>
                    )}
                  </AuthContextProvider>
                </ApolloProvider>
              </CharacterProvider>
            </RollbarProvider>

            {waitForSplash && (
              <SplashScreen
                onComplete={() => this.setState({ waitForSplash: false })}
              />
            )}
          </>
        </ThemeProvider>
      </>
    );
  }
}
