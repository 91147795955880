import { gql } from '@apollo/client';

export const REFRESH_TOKEN = gql`
  mutation refreshToken($token: String!) {
    refreshToken(input: { token: $token }) {
      accessToken
      refreshToken
      expiresIn
      tokenType
    }
  }
`;

export default REFRESH_TOKEN;
