import React, {
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  ReactNode,
} from 'react';

import { StyledInput, StyledSelect, StyledTextArea } from './styles';
import Button from '../button';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  type?: HTMLInputTypeAttribute;
  required?: boolean;
  // ONLY for select|button|submit types
  children?: ReactNode;
}

function Input({ type = 'text', children, ...props }: InputProps) {
  let realChildren: React.ReactNode | null = null;
  let InputComponent: React.ElementType = StyledInput;

  switch (type) {
    case 'textarea':
      InputComponent = StyledTextArea;
      break;
    case 'select':
      InputComponent = StyledSelect;
      realChildren = children;
      break;
    case 'submit':
    case 'button':
      InputComponent = Button;
      realChildren = children;
      break;
    default:
      InputComponent = StyledInput;
  }

  return (
    <InputComponent type={type} {...props}>
      {realChildren}
    </InputComponent>
  );
}

export default Input;
