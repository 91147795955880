import React from 'react';

import { hasRequiredRole } from '~/shared/config/hierarchy';

import { useLoginState } from './login.hook';

export const WITH_PERMISSION_MODE = {
  HIDDEN: 'hidden',
  DISABLED: 'disabled',
};

export function withPermissions({
  requiredRole,
  mode = WITH_PERMISSION_MODE.HIDDEN,
} = {}) {
  return (Component) => {
    return function ComponentWithPermissions(props) {
      const { user } = useLoginState();
      const hasAccess = hasRequiredRole(user, requiredRole);
      const additionalProps = {};
      if (!hasAccess) {
        if (mode === WITH_PERMISSION_MODE.HIDDEN) {
          return null;
        }
        if (mode === WITH_PERMISSION_MODE.DISABLED) {
          additionalProps.disabled = true;
        }
      }

      return <Component {...props} {...additionalProps} />;
    };
  };
}
