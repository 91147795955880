import { type Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { meetsContrastGuidelines } from 'polished';

import { breakpoint } from '~/shared/styles/utils';

const Panel = styled.div`
  position: relative;
  flex: 1;
  max-width: 100%;
  max-height: 100%;
  border-radius: 40px;
  background-color: ${(p) => p.theme.bright};
  box-shadow: ${(p) => p.theme.shadows.medium};
  padding: 20px;
  margin: auto;
  justify-self: stretch;

  ${breakpoint('small')} {
    padding: 30px;
  }
  ${breakpoint('medium')} {
    padding: 50px 70px;
  }
`;

type Sentiments = keyof Pick<
  Theme,
  | 'positive'
  | 'negative'
  | 'dark500'
  | 'positive100'
  | 'negative100'
  | 'bright100'
  | 'bright'
>;

function getPanelColor(p: { theme: Theme; sentiment?: Sentiments }) {
  const background = p.theme[p.sentiment || 'bright100'] || p.theme.bright100;
  const { dark200, bright } = p.theme;
  const color = meetsContrastGuidelines(background, dark200).AA
    ? dark200
    : bright;

  return { color, background };
}

export const NestedPanel = styled.div<{ sentiment?: Sentiments }>`
  flex: 1;
  border-radius: 20px;
  padding: 10px;
  ${getPanelColor}
  align-self: stretch;

  ${breakpoint('small')} {
    padding: 20px;
  }
`;

export const PanelTitle = styled.h2`
  font-size: 1.375rem;
  line-height: 1.875rem;
  margin-bottom: 10px;
`;
export const PanelIntro = styled.p`
  margin-bottom: 20px;
`;

export const PanelHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  ${PanelIntro} {
    margin-bottom: 0;
  }
`;

const StatFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;
const StatWrapper = styled.div`
  margin-left: 10px;
`;

interface PanelProps {
  title: string;
  sentiment?: Sentiments;
  children: React.ReactNode;
}

export function StatPanel({ title, sentiment, children }: PanelProps) {
  return (
    <NestedPanel sentiment={sentiment}>
      <StatFlex>
        <p>
          <em>{title}</em>
        </p>
        <StatWrapper>{children}</StatWrapper>
      </StatFlex>
    </NestedPanel>
  );
}

export default Panel;
