import info from '@bitsoflove/console-info';
import getConfig from 'next/config';

const {
  publicRuntimeConfig = {
    STATIC_DIR: '/static/',
  },
} = getConfig() || {};

if (process.browser && publicRuntimeConfig.BUILD_INFO) {
  info(publicRuntimeConfig.BUILD_INFO);
}

export const {
  STATIC_DIR,
  BASE_URL,
  BRANCH,
  ENVIRONMENT,
  API_BASE_URL,
  BUILD_INFO,

  // Custom project environment variables
  FONEMI_FRONTPAGE_URL,
  GTM_TRACKING_ID,
  LOCALE,
} = publicRuntimeConfig;

export const SILENCE = `${STATIC_DIR}audio/silence.mp3`;
export const SILENCE_LONG = `${STATIC_DIR}audio/silence-5.mp3`;
