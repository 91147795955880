import styled from '@emotion/styled';
import React, { useContext, useState } from 'react';

const CharacterContext = React.createContext();

const CharacterPattern = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${(p) => p.image});
`;

export function CharacterProvider({ children }) {
  const [character, setCharacter] = useState();

  return (
    <CharacterContext.Provider value={{ character, setCharacter }}>
      {character?.background?.pattern && (
        <CharacterPattern image={character.background.pattern} />
      )}
      {children}
    </CharacterContext.Provider>
  );
}

export function useCharacterContext() {
  const { character, setCharacter } = useContext(CharacterContext);

  return { character, setCharacter };
}
