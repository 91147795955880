import styled from '@emotion/styled';

export const PanelWrapper = styled.div`
  margin: auto;
  padding: 20px;
  max-width: 620px;
`;
export const ModalOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  background-color: ${(p) => p.theme.dark500a50};
  z-index: 9998;

  &.enter {
    opacity: 0;
    overflow: hidden;

    ${PanelWrapper} {
      transform: translateY(100px);
    }
  }
  &.enter-active {
    opacity: 1;
    transition: opacity 0.2s;

    ${PanelWrapper} {
      transform: translateY(0);
      transition: transform 0.2s;
    }
  }
  &.exit {
    opacity: 1;

    ${PanelWrapper} {
      transform: translateY(0);
    }
  }
  &.exit-active {
    opacity: 0;
    transition: opacity 0.2s;
    overflow: hidden;

    ${PanelWrapper} {
      transform: translateY(-100px);
      transition: transform 0.2s;
    }
  }
`;

export const ModalActions = styled.div`
  display: flex;
  padding: 20px;
  width: 100%;
  height: 110px;
  justify-content: space-between;
  align-items: center;
`;
