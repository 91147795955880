import React from 'react';

import { STATIC_DIR } from '~/shared/config';

const icons = {
  bullet: <circle cx={16} cy={16} r={16} fill="currentColor" />,
  add: (
    <path
      d="M31.5556 18.2221H18.2222V31.5554H13.7778V18.2221H0.444458V13.7777H13.7778V0.444336H18.2222V13.7777H31.5556V18.2221Z"
      fill="currentColor"
    />
  ),
  'arrow-left': (
    <path d="M7.98 14H32V18H7.98V24L0 16L7.98 8V14Z" fill="currentColor" />
  ),
  'arrow-right': (
    <path d="M24.02 14H0V18H24.02V24L32 16L24.02 8V14Z" fill="currentColor" />
  ),
  dropdown: (
    <path
      d="M6.82579 14.7629L14.0398 21.9769C15.1261 23.0632 16.8808 23.0632 17.9671 21.9769L25.1811 14.7629C26.9358 13.0082 25.6824 10 23.2035 10H8.77551C6.29657 10 5.07103 13.0082 6.82579 14.7629Z"
      fill="currentColor"
    />
  ),
  dropup: (
    <path
      d="M25.1742 18.0286L17.9602 10.8146C16.8739 9.7283 15.1192 9.7283 14.0329 10.8146L6.8189 18.0286C5.0642 19.7833 6.3176 22.7915 8.7965 22.7915L23.2245 22.7915C25.7034 22.7915 26.929 19.7833 25.1742 18.0286Z"
      fill="currentColor"
    />
  ),
  home: (
    <path
      d="M16 6.43059L24 13.8424V26.7059H20.8V16.8235H11.2V26.7059H8V13.8424L16 6.43059ZM16 2L0 16.8235H4.8V30H14.4V20.1176H17.6V30H27.2V16.8235H32L16 2Z"
      fill="currentColor"
    />
  ),
  sound: (
    <path
      d="M0 10.6667V21.3334H7.11111L16 30.2222V1.77782L7.11111 10.6667H0ZM12.4444 10.3645V21.6356L8.58667 17.7778H3.55556V14.2223H8.58667L12.4444 10.3645ZM24 16C24 12.8534 22.1867 10.1512 19.5556 8.8356V23.1467C22.1867 21.8489 24 19.1467 24 16ZM19.5556 0.408936V4.07116C24.6933 5.60004 28.4444 10.3645 28.4444 16C28.4444 21.6356 24.6933 26.4 19.5556 27.9289V31.5911C26.6844 29.9734 32 23.6089 32 16C32 8.39115 26.6844 2.02671 19.5556 0.408936Z"
      fill="currentColor"
    />
  ),
  fien: (
    <image
      href={`${STATIC_DIR}images/test/hud/fien.png`}
      height="32"
      width="32"
    />
  ),
  fonemi: (
    <image
      href={`${STATIC_DIR}favicon/favicon-32x32.png`}
      height="32"
      width="32"
    />
  ),
  play: (
    <path
      d="M24.2549 12.5423C22.7409 11.4688 20.9746 10.5027 19.3344 9.53662C15.8018 7.38971 12.1429 5.24281 8.61028 3.20325C8.10561 2.88121 7.60095 2.98856 7.22245 3.20325C6.71778 3.20325 6.21312 3.52528 6.08695 4.16935C4.69912 11.3615 7.47478 18.4463 6.21312 25.6384C6.21312 25.7458 6.21312 25.8531 6.21312 25.8531C5.58229 26.6045 5.96078 28 7.47478 28C9.49344 27.8927 11.0074 26.8192 12.5214 25.8531C14.2878 24.7796 16.0541 23.7062 17.6943 22.6327C19.4606 21.5593 21.2269 20.4858 22.8671 19.4124C24.2549 18.5536 26.0212 17.8022 26.2736 16.192C26.5259 14.5818 25.5166 13.401 24.2549 12.5423ZM22.1101 16.4067C21.3531 16.8361 20.7223 17.2655 19.9653 17.6949C18.5774 18.5536 17.1896 19.4124 15.8018 20.2711C14.4139 21.2373 13.0261 22.096 11.5121 23.0621C10.8813 23.4915 10.1243 23.9209 9.49344 24.3503C9.99811 18.4463 8.10561 12.4349 8.73645 6.53095C11.0074 7.81909 13.4046 9.10724 15.5494 10.5027C16.9373 11.3615 18.3251 12.1129 19.5868 12.9717C20.3438 13.401 21.1008 13.8304 21.8578 14.3672C22.2363 14.5818 23.1194 15.0112 23.1194 15.548C23.2456 15.9773 22.6148 16.192 22.1101 16.4067Z"
      fill="currentColor"
    />
  ),
  replay: (
    <>
      <path
        d="M14.1542 21.0768C15.41 21.3525 16.7413 21.6354 18.0229 21.6354C19.5459 21.6354 22.1607 19.5 22.1607 17.1563C22.1607 15.6304 22.2789 14.3446 21.1883 13.2151C19.7096 11.6836 16.5 10 13.5 13.4999"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M12.106 11.3945C11.4423 12.3071 11.1935 13.2013 10.9992 14.2703C10.9353 14.6213 10.8944 16.0495 11.0819 16.0495C12.6301 16.0495 13.8191 16.217 15.2714 15.4909"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </>
  ),
  email: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 6.3875C32 4.6275 30.56 3.1875 28.8 3.1875H3.2C1.44 3.1875 0 4.6275 0 6.3875V25.5875C0 27.3475 1.44 28.7875 3.2 28.7875H28.8C30.56 28.7875 32 27.3475 32 25.5875V6.3875ZM28.8 6.3875L16 14.3875L3.2 6.3875H28.8ZM28.8 25.5875H3.2V9.5875L16 17.5875L28.8 9.5875V25.5875Z"
      fill="currentColor"
    />
  ),
  facebook: (
    <path
      d="M32 16.0974C32 7.20488 24.8387 0 16 0C7.16131 0 0 7.20488 0 16.0974C0 24.1318 5.85097 30.7914 13.5 32V20.7507H9.4355V16.0974H13.5V12.5507C13.5 8.51669 15.8871 6.28837 19.5432 6.28837C21.2942 6.28837 23.1252 6.6025 23.1252 6.6025V10.5619H21.1071C19.12 10.5619 18.5 11.803 18.5 13.0759V16.0974H22.9374L22.2278 20.7507H18.5V32C26.1491 30.7914 32 24.1318 32 16.0974Z"
      fill="currentColor"
    />
  ),
  youtube: (
    <path
      d="M26.5846 4.75H5.41539C2.42501 4.75 0 7.21494 0 10.2545V21.764C0 24.8036 2.42501 27.2685 5.41539 27.2685H26.5846C29.575 27.2685 32 24.8036 32 21.764V10.2545C32 7.21494 29.575 4.75 26.5846 4.75ZM22.2673 16.2299L12.6673 21.2341C12.5904 21.2731 12.5 21.2712 12.4269 21.2262C12.3539 21.1813 12.3077 21.1011 12.3077 21.0132V11.0049C12.3077 10.917 12.3539 10.8368 12.4269 10.7919C12.5 10.7469 12.5904 10.7449 12.6673 10.7841L22.2673 15.7882C22.35 15.8312 22.4019 15.9172 22.4019 16.011C22.4019 16.1048 22.35 16.1909 22.2673 16.2339V16.2299Z"
      fill="currentColor"
    />
  ),
  'caret-left': (
    <>
      <g clipPath="url(#clip0_5_2)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.885 28.24L13.6717 16L25.885 3.76L22.125 0L6.12498 16L22.125 32L25.885 28.24Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_5_2">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
  'caret-right': (
    <>
      <g clipPath="url(#clip0_5_5)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.125 3.765L18.3383 16.005L6.125 28.245L9.885 32.005L25.885 16.005L9.885 0.00500488L6.125 3.765Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_5_5">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
  'caret-up': (
    <>
      <g clipPath="url(#clip0_6_9)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.525 24.2672L15 12.8172L26.475 24.2672L30 20.7422L15 5.74219L0 20.7422L3.525 24.2672Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_6_9">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
  check: (
    <path
      d="M16 0C7.168 0 0 7.168 0 16C0 24.832 7.168 32 16 32C24.832 32 32 24.832 32 16C32 7.168 24.832 0 16 0ZM16 28.8C8.944 28.8 3.2 23.056 3.2 16C3.2 8.944 8.944 3.2 16 3.2C23.056 3.2 28.8 8.944 28.8 16C28.8 23.056 23.056 28.8 16 28.8ZM23.344 8.928L12.8 19.472L8.656 15.344L6.4 17.6L12.8 24L25.6 11.2L23.344 8.928Z"
      fill="currentColor"
    />
  ),
  'check-alt': (
    <path
      d="M23.344 8.928L12.8 19.472L8.656 15.344L6.4 17.6L12.8 24L25.6 11.2L23.344 8.928Z"
      fill="currentColor"
    />
  ),
  'play-solid': (
    <path d="M3.4375 0V32L28.5804 16L3.4375 0Z" fill="currentColor" />
  ),
  pause: (
    <path
      d="M0 0H13.3333V32H0V0ZM18.6667 0H32V32H18.6667V0Z"
      fill="currentColor"
    />
  ),
  menu: (
    <path
      d="M32 8.8V4H0V8.8H32ZM32 18.4V13.6H0V18.4H32ZM32 28V23.2H0V28H32Z"
      fill="currentColor"
    />
  ),
} as const;

export default icons;
