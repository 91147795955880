import { gql, useApolloClient } from '@apollo/client';
import { bool, node } from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { SILENCE, SILENCE_LONG, STATIC_DIR } from '~/shared/config';

const AUDIO_DIR = `${STATIC_DIR}audio/`;
const ENCOURAGEMENTS_DIR = `${STATIC_DIR}audio/pretest-encouragements/`;

const FeedbackAudioContext = React.createContext({
  loading: true,
  audio: [],
});

const GET_FEEDBACK_AUDIO_QUERY = gql`
  query getFeedbackAudio {
    app {
      feedback {
        id
        slug
        type
        audio {
          id
          src
        }
      }
    }
  }
`;

const fallback = {
  'silence-short': SILENCE,
  'silence-long': SILENCE_LONG,
  'home-intro': `${AUDIO_DIR}home/intro.mp3`,
  'home-start-test': `${AUDIO_DIR}home/start-test.mp3`,
  // 'home-replay-test': `${AUDIO_DIR}home/start-test.mp3`, // missing
  'home-test-locked': `${AUDIO_DIR}home/test-locked.mp3`,
  'home-start-pretest-isoleren': `${AUDIO_DIR}home/start-pretest-isoleren.mp3`,
  'home-start-pretest-analyse-synthese': `${AUDIO_DIR}home/start-pretest-analyse-synthese.mp3`,
  'home-start-pretest-manipuleren': `${AUDIO_DIR}home/start-pretest-manipuleren.mp3`,

  'feedback-test-success-1': `${AUDIO_DIR}test/victory-1.mp3`,
  'feedback-test-success-2': `${AUDIO_DIR}test/victory-2.mp3`,
  'feedback-test-success-3': `${AUDIO_DIR}test/victory-3.mp3`,

  'test-encouragement-1': `${AUDIO_DIR}test/goed-bezig-we-oefenen-nog-even-verder.mp3`,

  'pretest-encouragement-1': `${ENCOURAGEMENTS_DIR}waw-wat-doe-je-dat-goed.mp3`,
  'pretest-encouragement-2': `${ENCOURAGEMENTS_DIR}wat-doe-jij-dat-goed-ik-sta-versteld.mp3`,
  'pretest-encouragement-3': `${ENCOURAGEMENTS_DIR}wat-ben-jij-een-kanjer.mp3`,
  'pretest-encouragement-4': `${ENCOURAGEMENTS_DIR}je-bent-goed-bezig.mp3`,
  'pretest-encouragement-5': `${ENCOURAGEMENTS_DIR}super-je-bent-een-krak.mp3`,
  'pretest-encouragement-6': `${ENCOURAGEMENTS_DIR}goed-zo.mp3`,
  'pretest-encouragement-7': `${ENCOURAGEMENTS_DIR}ga-zo-door-je-doet-het-prima.mp3`,
  'pretest-encouragement-8': `${ENCOURAGEMENTS_DIR}doe-zo-verder.mp3`,
  'pretest-encouragement-9': `${ENCOURAGEMENTS_DIR}dat-gaat-goed.mp3`,

  'instruction-test-audio': `${AUDIO_DIR}audio-test.mp3`,

  'instruction-repeat-exercise': `${AUDIO_DIR}test/instruction-repeat-exercise.mp3`,
  'feedback-wrong-answer': `${AUDIO_DIR}test/wrong-answer.mp3`,
  'feedback-correct-answer': `${AUDIO_DIR}test/correct-answer.mp3`,
  'instruction-test-failed-school': `${AUDIO_DIR}test/test-failed-feedback-school.mp3`,
  'instruction-test-failed-home': `${AUDIO_DIR}test/test-failed-feedback-home.mp3`,
};

export const FeedbackAudioProvider = ({ children, active }) => {
  // NOTE: not using query because cache is reset entirely when changing users
  // feedback audio "never" changes, so we can just keep it in state.
  const client = useApolloClient();
  const [audio, setAudio] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let canceled = false;
    if (active && !audio) {
      setLoading(true);
      client
        .query({
          query: GET_FEEDBACK_AUDIO_QUERY,
        })
        .then((result) => {
          if (!canceled) {
            setAudio(result?.data.app.feedback);
            setLoading(false);
          }
        });
    }

    return () => {
      canceled = true;
      setLoading(false);
    };
  }, [active, audio]);

  return (
    <FeedbackAudioContext.Provider value={{ audio: audio || [], loading }}>
      {children}
    </FeedbackAudioContext.Provider>
  );
};
FeedbackAudioProvider.propTypes = {
  children: node.isRequired,
  active: bool.isRequired,
};

export function useFeedbackAudio() {
  const { audio, loading } = useContext(FeedbackAudioContext);
  const findOne = useCallback(
    (slug) => {
      if (loading) {
        return null;
      }
      return (
        audio.find((find) => find.slug === slug)?.audio?.src || fallback[slug]
      );
    },
    [loading],
  );
  const findMultiple = useCallback(
    (slugs) => {
      if (loading) {
        return [];
      }
      return slugs
        .map((slug) => {
          return (
            audio.find((find) => find.slug === slug)?.audio.src ||
            fallback[slug]
          );
        })
        .filter((slug) => !!slug);
    },
    [loading],
  );
  return {
    loading,
    findOne,
    findMultiple,
  };
}
