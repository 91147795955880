import styled from '@emotion/styled';

const PageActions = styled.div`
  display: flex;
  padding: 20px;
  width: 100%;
  height: 110px;
  justify-content: space-between;
  align-items: center;
`;

export default PageActions;
