import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';

// Respect these values, do not dismember poor Fien
const MIN_REALISTIC_ROTATION = -45;
const MAX_REALISTIC_ROTATION = 0;

const wave = ({ intensity = 0.5 }) => keyframes`
  0% {
    transform: rotate(${MAX_REALISTIC_ROTATION * intensity}deg);
  }
  100% {
    transform: rotate(${MIN_REALISTIC_ROTATION * intensity}deg);
  }
`;

const fade = (p: { fade?: boolean }) =>
  p.fade
    ? css`
        mask-image: linear-gradient(
          rgba(0, 0, 0, 1) 75%,
          rgba(0, 0, 0, 0) 100%
        );
      `
    : '';

export const FienWrapper = styled.div<{ width: string; height?: string }>`
  position: relative;
  width: ${(p) => p.width};
  height: ${(p) => p.height || 'auto'};
  max-width: 400px;
  margin: auto;
`;
export const FienMask = styled.div<{ clip?: boolean; fade?: boolean }>`
  position: relative;
  width: 200%;
  margin-left: -100%;
  padding-left: 100%;
  height: 100%;
  overflow: hidden;

  overflow: ${(p) => (p.clip ? 'hidden' : 'visible')};
  ${fade}
`;

export const FienArm = styled.div<{ intensity: number }>`
  position: absolute;
  width: 23.7%;
  left: 50%;
  margin-top: 5.97%;
  background-size: cover;
  transform-origin: 94% 75%;

  animation: ${wave} 1s infinite alternate ease-in-out;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;
export const FienBody = styled.div`
  position: relative;
  width: 76.2%;
  padding-top: 197.2%;
  right: 0;
  margin-left: auto;
  background: url(${(p) => p.theme.staticDir}images/fien/wave-body.png) right
    top no-repeat;
  background-size: cover;
`;
