import { bool, instanceOf, oneOfType } from 'prop-types';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Alert from '~/shared/ui/alert';

function isCreditsError(error) {
  return (
    error.graphQLErrors?.[0]?.debugMessage ===
    'Not enough credits to handle request.'
  );
}

function LoginError({ error }) {
  const { t } = useTranslation('auth');
  return (
    error && (
      <>
        <Alert type="error">
          {isCreditsError(error)
            ? t('login.general.not_enough_credits')
            : t('login.general.password_login_failed')}
        </Alert>
        <Alert
          type="info"
          size="sm"
          style={{
            // position: 'relative',
            marginTop: -15,
            // left: 15,
            // borderTopLeftRadius: 0,
          }}
        >
          <Trans
            t={t}
            i18nKey="login.general.login_failed_check_region"
            components={{
              a: <a rel="noopener noreferrer" />,
            }}
          />
        </Alert>
      </>
    )
  );
}

LoginError.propTypes = {
  error: oneOfType([bool, instanceOf(Error)]),
};
LoginError.defaultProps = {
  error: null,
};

export default LoginError;
