'use client';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { type Locale } from '~/shared/i18n';

const TimezoneLocaleMap: Record<string, Locale> = {
  'Europe/Brussels': 'nl-BE',
  'Europe/Amsterdam': 'nl-NL',
};

export default function useGuessedTimezoneLocale() {
  const [guessed, setGuessed] = useState<string | undefined>();

  useEffect(() => {
    setGuessed(TimezoneLocaleMap[dayjs.tz.guess()] || undefined);
  }, []);

  return guessed;
}
