// merge policy based on:
// import { offsetLimitPagination } from "@apollo/client/utilities";

export function pageCountPagination() {
  return {
    keyArgs: ['filters'],
    merge(existing, incoming, _a) {
      const { args } = _a;
      const merged = existing?.items ? existing.items.slice(0) : [];
      const { items } = incoming;

      if (args) {
        const { page = incoming.currentPage, count = incoming.perPage } = args;
        const offset = (page - 1) * count;
        for (let i = 0; i < items.length; i += 1) {
          merged[offset + i] = items[i];
        }
      } else {
        merged.push(...items);
      }

      return {
        ...incoming,
        items: merged,
      };
    },

    // This implementation seems to be unworking because the page arg is not passed correctly for some reason
    // read(existing, { args: { page, count } }) {
    //   // A read function should always return undefined if existing is
    //   // undefined. Returning undefined signals that the field is
    //   // missing from the cache, which instructs Apollo Client to
    //   // fetch its value from your GraphQL server.
    //   const offset = (page - 1) * count;
    //   return (
    //     existing && {
    //       ...existing,
    //       items: existing.items?.slice(offset, offset + count),
    //     }
    //   );
    // },
  };
}

export function simpleItemsMergePolicy() {
  return {
    keyArgs: ['filters'],
    fields: {
      items: {
        merge(existing = [], incoming = []) {
          return [...existing, ...incoming];
        },
      },
    },
  };
}

export function noCacheMergePolicy() {
  return {};
}
