import { type Theme } from '@emotion/react';

import { BREAKPOINTS } from './theme';

export function breakpoint(
  name: (typeof BREAKPOINTS)[keyof typeof BREAKPOINTS],
) {
  return ({ theme }: { theme: Theme }) =>
    `@media ${theme?.breakpoints?.[name] || 'screen'}`;
}
