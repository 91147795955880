import { type Theme } from '@emotion/react';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useMemo } from 'react';

const SPECIAL_COLORS: Record<string, keyof Theme['colors']> = {
  'nl-NL': 'orange',
  'nl-BE': 'red',
};

export function useBubbleColor(generatorValue: string) {
  const theme = useTheme();
  const generatedColor = useMemo(() => {
    if (generatorValue) {
      // special values
      if (SPECIAL_COLORS[generatorValue]) {
        return theme.colors[SPECIAL_COLORS[generatorValue]];
      }

      const colors = theme.colorScale;
      let hash = 0;
      for (let i = 0; i < generatorValue.length; i += 1) {
        /* eslint-disable no-bitwise */
        hash = generatorValue.charCodeAt(i) + ((hash << 5) - hash);
        hash &= hash;
        /* eslint-enable no-bitwise */
      }
      hash = ((hash % colors.length) + colors.length) % colors.length;
      return colors[hash];
    }
    return null;
  }, [theme, generatorValue]);
  return {
    background: generatedColor || theme.bright,
    label: generatedColor ? theme.bright : theme.secondaryDarker100,
  };
}

export const BubbleLabel = styled.h3`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-style: normal;
  font-weight: 800;
  font-size: 1.5rem;
  line-height: 1;
  color: ${(p) => p.color};
  transition: opacity 0.2s;
`;

const bubbleStyles = (p: { width: string }) => css`
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: ${p.width || 'auto'};

  > svg {
    display: block;
    width: calc(100% + 2px);
    margin-right: -2px;
    margin-bottom: -2px;
    height: auto;
    transition: opacity 0.2s;
  }
`;

const hoverStyles = () => css`
  > svg {
    opacity: 0.9;
  }
  ${BubbleLabel} {
    opacity: 0.9;
  }
`;

export const StyledBubble = styled.span<{ width: string }>`
  ${bubbleStyles}
`;

export const StyledBubbleButton = styled.button<{ width: string }>`
  cursor: pointer;
  ${bubbleStyles}

  border: none;
  background: transparent;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:hover {
    ${hoverStyles}
  }
`;
