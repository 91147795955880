import { type Theme, css } from '@emotion/react';
import styled from '@emotion/styled';

const baseInputStyles = (p: { theme: Theme }) => css`
  background-color: ${p.theme.bright100};
  border-radius: 15px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 11px 18px;
  width: 100%;
  margin-bottom: 10px;
  box-sizing: border-box;
  border: 2px solid transparent;
  color: ${p.theme.dark200};

  &:focus {
    outline: none;
    border: 2px solid ${p.theme.brand};
  }
`;

export const StyledInput = styled.input`
  ${baseInputStyles}
`;
export const StyledTextArea = styled.textarea`
  ${baseInputStyles}
  min-height: 200px;
`;

export const StyledSelect = styled.select`
  ${baseInputStyles}
`;
