import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * NOTE: to prevent SSR from crashing on localStorage the initial state is fetched in a useEffect hook.
 *
 * This means you will get two values shortly one after the other. If no value is found in persisted state it will be forced to null to differentiate between `undefined` and `null`. (If no initial value is set.)
 *
 * @param {String} storageKey the key where to store the data, make it unique
 * @param {Function} copyValue function to copy value somewhere else BEFORE setting state (eg. apollo links)
 * @returns [state, setState]
 */
export default function usePersistantState(storageKey, copyValue) {
  const [state, setState] = useState();
  const copyRef = useRef(copyValue);
  const setAndPersistState = useCallback(
    (value) => {
      copyRef.current?.(value);
      setState(value);
      localStorage.setItem(storageKey, JSON.stringify(value));
    },
    [setState],
  );

  useEffect(() => {
    const parseState = (state) => {
      try {
        const persistedState = JSON.parse(state || 'null');
        const nextValue = persistedState || null;
        copyRef.current?.(nextValue);
        setState(nextValue);
      } catch (e) {
        console.warn('[usePersistantState] Invalid JSON, ignoring');
      }
    };

    parseState(localStorage.getItem(storageKey));

    const handleStorageEvent = (event) => {
      if (event.newValue !== event.oldValue) {
        parseState(event.newValue);
      }
    };

    window.addEventListener('storage', handleStorageEvent);

    return () => {
      window.removeEventListener('storage', handleStorageEvent);
    };
  }, []);

  return [state, setAndPersistState];
}
